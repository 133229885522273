<template>
  <div class="analysis-program-wrapper">
    <!--Analysis Program 1 -->
    <v-expansion-panel
      v-if="schema.analysisProgram1.show && showProgram1"
      class="expansion-panel"
      key="AnalysisProgram1Section"
    >
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.ANALYSIS_PROGRAM_1"
          :types="
            lessonPlanData && lessonPlanData.analysisProgram1
              ? lessonPlanData.analysisProgram1.types
              : []
          "
          :time="
            lessonPlanData && lessonPlanData.analysisProgram1
              ? lessonPlanData.analysisProgram1.time
              : ''
          "
          :canEditType="auth.canSaveProjectLP"
          @update-type-of-block-to-state="
            (val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, val)
          "
          :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, e)"
          :isNotReadOnly="auth.canSaveProjectLP"
          :displayTime="true"
          :canEditTime="auth.canSaveProjectLP"
          @set-timer-input="
            (val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, val)
          "
          :allowedTimeValues="schema.analysisProgram1.allowedTimes"
          :schema="schema"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content">
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_PROGRAM_LEGACY_DATA"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
          subPath="analysisProgram1"
          :initialActive="isSlideLayoutEnabled('analysisProgram1.instructions.0', lessonPlanData)"
          :showLegacyComponentSwitcher="schema.analysisProgram1.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show']"
        >
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="auth.canSaveProjectLP"
              subPath="analysisProgram1.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.analysisProgram1.configurableMultiSlide.maxSlidesCount"
            />
          </template>
          <template #false-slot>
            <GenericAnalysisSection
              :schema="schema.analysisProgram1"
              :lessonPlanData="lessonPlanData"
              :canShowDiffIcon="canShowDiffIcon"
              :isNotReadOnly="auth.canSaveProjectLP"
              :signedUrls="signedUrls"
              :fileValidators="fileValidators"
              :canAppproveAsset="canAppproveAsset"
              :isAssetUploadRequired="isAssetUploadRequired"
              :acceptableFileType="acceptableFileType"
              :showImageBrief="showImageBrief"
            />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Analysis Program 1 END -->

    <!--Analysis Program 2 -->
    <v-expansion-panel
      v-if="schema.analysisProgram2.show && showProgram2"
      class="expansion-panel"
      key="AnalysisProgram1Section"
    >
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.ANALYSIS_PROGRAM_2"
          :types="
            lessonPlanData && lessonPlanData.analysisProgram2
              ? lessonPlanData.analysisProgram2.types
              : []
          "
          :time="
            lessonPlanData && lessonPlanData.analysisProgram2
              ? lessonPlanData.analysisProgram2.time
              : ''
          "
          :canEditType="auth.canSaveProjectLP"
          @update-type-of-block-to-state="
            (val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, val)
          "
          :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, e)"
          :isNotReadOnly="auth.canSaveProjectLP"
          :displayTime="lessonPlanData.analysisProgram2.show"
          :canEditTime="auth.canSaveProjectLP"
          @set-timer-input="
            (val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, val)
          "
          :allowedTimeValues="schema.analysisProgram2.allowedTimes"
          :schema="schema"
          :initialHeaderCheckValue="lessonPlanData.analysisProgram2.show"
          @headerCheckboxChange="(val) => (lessonPlanData.analysisProgram2.show = val)"
          :displayTrailing="lessonPlanData.analysisProgram2.show"
          :displayComments="lessonPlanData.analysisProgram2.show"
          :headerCheck="true"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="section-title-content"
        v-show="lessonPlanData.analysisProgram2.show"
      >
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_PROGRAM_LEGACY_DATA"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
          subPath="analysisProgram2"
          :initialActive="isSlideLayoutEnabled('analysisProgram2.instructions.0', lessonPlanData)"
          :showLegacyComponentSwitcher="schema.analysisProgram2.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show']"
        >
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="auth.canSaveProjectLP"
              subPath="analysisProgram2.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.analysisProgram2.configurableMultiSlide.maxSlidesCount"
            />
          </template>
          <template #false-slot>
            <GenericAnalysisSection
              :schema="schema.analysisProgram2"
              :lessonPlanData="lessonPlanData"
              :canShowDiffIcon="canShowDiffIcon"
              :isNotReadOnly="auth.canSaveProjectLP"
              :signedUrls="signedUrls"
              :fileValidators="fileValidators"
              :canAppproveAsset="canAppproveAsset"
              :isAssetUploadRequired="isAssetUploadRequired"
              :acceptableFileType="acceptableFileType"
              :showImageBrief="showImageBrief"
            />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Analysis Program 2 END -->
  </div>
</template>
<script>
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import ExpansionPanelHeader from '@/components/lessonPlanTemplate/ExpansionPanelHeader';
import GenericAnalysisSection from '@/components/lessonPlanTemplate/commonLpComponents/GenericAnalysisDiscussion';
import { COMMENT_SECTION_NAMES_HASH } from '@/constants/LessonPlan';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled } from '@/utils/slideLayoutHelpers.js';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';
export default {
  name: 'AnalysisProgram',
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
      FILE_UPLOADING_STATUS,
      COMMENT_SECTION_NAMES_HASH,
      COMPONENT_TO_DS_MAP,
    };
  },
  components: {
    ExpansionPanelHeader,
    GenericAnalysisSection,
    XSRightSwitch,
    MultiSlideLayout,
  },
  methods: {
    isSlideLayoutEnabled,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    fileValidators: {
      type: Array,
      default: () => [],
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    diff: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => ({}),
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },

    showProgram1: {
      type: Boolean,
      default: false,
    },
    showProgram2: {
      type: Boolean,
      default: false,
    },
    updateTimeOfBlockToState: {
      type: Function,
      required: true,
    },
    updateTypeOfBlockToState: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.analysis-program-wrapper {
  width: 100%;
  padding: 0;
  margin: 16px 0 0 0;
}
</style>
