const getTeacherTip = () => {
  return {
    name: 'row',
    props: { justify: 'space-between' },
    children: [
      {
        name: 'expanded',
        child: {
          name: 'column',
          children: [
            {
              name: 'teacherTip',
              props: { enabled: false },
              content: '',
            },
          ],
        },
      },
    ],
  };
};
const LAYOUT_1_OBJECT = {
  layoutName: 'layout1',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_2_OBJECT = {
  layoutName: 'layout2',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_3_OBJECT = {
  layoutName: 'layout3',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'list',
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_4_OBJECT = {
  layoutName: 'layout4',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'list',
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_5_OBJECT = {
  layoutName: 'layout5',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'center' },
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                },
              },
            },
          ],
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_6_OBJECT = {
  layoutName: 'layout6',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'center' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_7_OBJECT = {
  layoutName: 'layout7',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

module.exports = {
  LAYOUT_1_OBJECT,
  LAYOUT_2_OBJECT,
  LAYOUT_3_OBJECT,
  LAYOUT_4_OBJECT,
  LAYOUT_5_OBJECT,
  LAYOUT_6_OBJECT,
  LAYOUT_7_OBJECT,
};
