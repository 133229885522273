import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
import WhatWeLearned from '@/components/lessonPlanTemplate/classroom/WhatWeLearned';
import { isSlideLayoutEnabled } from '@/utils/slideLayoutHelpers.js';

export const classRoomLessonMixin = {
  mixins: [baseMixin],
  components: {
    WhatWeLearned,
  },
  data() {
    return {
      Schema: {
        aim: {
          showKeyQuestion: true,
          maxResourcesLength: 5,
          resourceMaxLength: 5,
        },
        action: {
          maxNoOfSteps: 100,
          maxNoOfSubStepsInStep: 100,
          maxSumOfStepsAndSubSteps: 500,
          allowedTimes: ['8 mins', '10 mins', '12 mins', '13 mins', '15 mins'],
          showSwitchableLayout: true,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 5,
          },
        },
        analysis: {
          showReadXSEEDContentBookCheckbox: false,
          showCodingConnection: true,
          maxNoOfSteps: 100,
          maxNoOfSubStepsInStep: 100,
          maxSumOfStepsAndSubSteps: 500,
          showSwitchableLayout: true,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 2,
          },
          allowedTimes: ['10 mins', '12 mins', '13 mins', '15 mins'],
        },
        application: {
          showLegacyClassWorkHomeWork: true,
          showClassWorkHomeWork: false,
          showDifferentiationTips: true,
          configurableMorePractice: {
            show: true,
            title: 'Thinking Out Loud',
            paragraphTextKey: 'application.thinking_out_loud.paragraph_text',
            items: 'application.thinking_out_loud',
            leafNode: 'thinking_out_loud',
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 2,
            slideLayoutCheckPath: 'application.thinking_out_loud.instructions.0',
            subPath: 'application.thinking_out_loud.instructions',
          },
          showSwitchableLayout: true,
        },
        assessment: {
          canShowCheck: true,
          canShowClassroomExplanation: true,
          configurableClassroomExplanation: {
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
        },
      },
    };
  },
  methods: {
    unApprovedAssetValidation() {
      let countUnApprovedAssets = validateAssetAuthorized(
        this.lessonPlan.data,
        ['assetId'],
        {
          assetId: 'assetApproved',
        },
        ['brief', 'placeholder', 'multimedia'],
        0
      );
      if (countUnApprovedAssets > 0) {
        this.$notify({
          title: 'Failed',
          text: `There are ${countUnApprovedAssets} unapproved assets`,
          type: 'error',
          duration: -1,
        });
        return false;
      }
      return true;
    },
    customValidations() {
      try {
        let lp = this.lessonPlan;

        if (!lp.data.analysis) throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS');
        if (!lp.data.analysis.activity_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD');
        if (!lp.data.analysis.activity_discussion.explanations) {
          if (!isSlideLayoutEnabled('analysis.activity_discussion.instructions.0', lp.data)) {
            throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD_EXPLANATION');
          }
        }
        if (!lp.data.analysis.generalized_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD');
        if (!lp.data.analysis.generalized_discussion.explanations) {
          if (!isSlideLayoutEnabled('analysis.generalized_discussion.instructions.0', lp.data)) {
            throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD_EXPLANATION');
          }
        }
        if (!lp.data.analysis.higher_order_thinking)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS');
        if (!lp.data.analysis.higher_order_thinking.explanations) {
          if (!isSlideLayoutEnabled('analysis.higher_order_thinking.instructions.0', lp.data)) {
            throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS_EXPLANATION');
          }
        }

        let isADHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.activity_discussion.explanations
        );
        if (isSlideLayoutEnabled('analysis.activity_discussion.instructions.0', lp.data)) {
          isADHasExplanationData = true;
        }
        let isGDHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.generalized_discussion.explanations
        );
        if (isSlideLayoutEnabled('analysis.generalized_discussion.instructions.0', lp.data)) {
          isADHasExplanationData = true;
        }
        let isHOTSHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.higher_order_thinking.explanations
        );
        if (isSlideLayoutEnabled('analysis.higher_order_thinking.instructions.0', lp.data)) {
          isADHasExplanationData = true;
        }
        if (!(isADHasExplanationData || isGDHasExplanationData || isHOTSHasExplanationData))
          throw Error(
            'Invalid LessonPlan Data. ERROR: Atleast one of Activity Discussion or Generalized Discussion or Higher Order Thinking should have valid explanations data.'
          );

        return true;
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
  },
};
