<template>
  <div @keydown.esc="escapeKeyPressed()">
    <v-form ref="FileInputForm">
      <v-checkbox
        color="success"
        style="width: 200px; height: 40px;"
        :class="isAssetApproved ? 'selected-box' : 'not-selected-box'"
        v-if="canFinalizeAsset"
        :background-color="isAssetApproved ? '#37B074' : '#D9D9D9'"
        @change="onUpdateAssetAsFinal"
        :input-value="isAssetApproved"
        :disabled="isAssetUploadRequired || (image_url_brief || '').length == 0"
      >
        <template v-slot:label>
          <div :style="isAssetApproved ? 'color: white' : 'color: black'" class="pt-2 pb-2 mb-0">
            Final Asset
          </div>
        </template>
      </v-checkbox>
      <div v-if="loadingStatus">
        <AssetUploadInProgressStatus
          :filename="getFileNameFromUrl(imageUrl[+isAssetUploadRequired])"
        />
      </div>
      <div v-else-if="(preview_url[+isAssetUploadRequired] || '').length > 0">
        <AssetPreviewDialog
          :key="diffIdentifier"
          :imageUrl="preview_url"
          :fileChangeHandler="onChange"
          :handleAssetReplaceAction="handleAssetReplaceAction"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged"
          :diffIdentifier="diffIdentifier"
          :isNotReadOnly="isNotReadOnly"
          :isAssetUploadRequired="isAssetUploadRequired"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="isAssetApproved"
          :ApproveAssetAndEmitResult="ApproveAssetAndEmitResult"
          @assetApprovedByUser="(val) => $emit('assetApprovedByUser', val)"
        />
      </div>
      <div v-else>
        <div
          class="image-upload"
          v-if="!useAssetVault"
          :style="fileInputValidator ? 'border: 1px solid red;' : ''"
        >
          <label :for="generateIdentifier()">
            <img
              src="@/assets/image.png"
              style="opacity: 0.25;"
              v-if="imagePlaceholder == 'image'"
            />
            <img
              src="@/assets/video.png"
              style="padding: 5px; opacity: 0.25;"
              v-else-if="imagePlaceholder == 'video'"
            />
            <img
              src="@/assets/audio.svg"
              style="padding: 5px; opacity: 0.25;"
              v-else-if="imagePlaceholder == 'audio'"
            />
            <img
              src="@/assets/file.png"
              style="padding: 20px; opacity: 0.25;"
              v-else-if="imagePlaceholder == 'pdf'"
            />
          </label>
          <v-file-input
            :id="generateIdentifier()"
            class="xs-file-input"
            refs="fileInput"
            dense
            outlined
            hide-details
            prepend-icon="mdi-image"
            color="accent"
            show-size
            :accept="fileAcceptType"
            :key="image_url"
            :label="label"
            :placeholder="getFileNameFromUrl(imageUrl[+isAssetUploadRequired])"
            @change="onChange"
            :clearable="false"
            :disabled="(imageUrl[+isAssetUploadRequired] || '').length > 0 || !isNotReadOnly"
            :loading="loadingStatus"
            @click="openPreviewDialog"
            :error-messages="errorMessages"
            :rules="[
              (v) =>
                !isRequired ||
                !isAssetUploadRequired ||
                (imageUrl[+isAssetUploadRequired] || '').length > 0 ||
                Errors.REQUIRED_FIELD,
            ]"
          ></v-file-input>
        </div>
        <div
          v-else
          class="image-upload"
          :style="fileInputValidator ? 'border: 1px solid red;' : ''"
        >
          <img src="@/assets/image.png" style="opacity: 0.25;" @click="openPreviewDialog" />
          <v-dialog v-model="showCentralizedAssetUploaderDialog" width="400">
            <v-card v-if="showCentralizedAssetUploaderDialog">
              <v-card-title>
                <h2>Insert Image</h2>
              </v-card-title>
              <v-card-text>
                <AssetVaultUpload
                  :key="`centralised-asset-vault-${generateIdentifier()}`"
                  :preset="CA_QUESTION_PRESET"
                  :appName="CA_APP_NAME"
                  :assetResponseHook="onChange"
                  :placeholder="`Supported ${
                    isAssetUploadRequired
                      ? '(png / jpeg / mp3 / mp4)'
                      : '(png / jpeg / doc / docx / pdf / mp3 / mp4)'
                  } `"
                  :acceptedFileTypes="
                    isAssetUploadRequired
                      ? ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg']
                      : [
                          'application/pdf',
                          'image/png',
                          'image/jpeg',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          'application/msword',
                          'video/mp4',
                          'audio/mpeg',
                        ]
                  "
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <template>
        <v-dialog
          @click:outside="showPreviewDialog = false"
          @keydown.esc="showPreviewDialog = false"
          v-model="showPreviewDialog"
          width="1000"
          :content-class="'lpForm-asset-preview-dialog-before-asset-upload'"
        >
          <div class="lpForm-asset-preview-wrapper">
            <v-card>
              <v-row class="preview-container">
                <v-col cols="12" sm="6" md="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-if="preview_url[0]" class="headline">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="brief-headline">
                              Brief -> {{ getFileNameFromUrl(imageUrl[0]) }}
                            </div>
                          </template>
                          <span> {{ getFileNameFromUrl(imageUrl[0]) }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-col cols="12" sm="3" md="12">
                    <v-col v-if="preview_url[0]">
                      <div
                        :style="isAssetUploadRequired ? 'margin-left: 0px' : 'margin-left: 500px;'"
                        v-if="getFileTypeFromUrl(preview_url[0]) == 'video'"
                      >
                        <video-player
                          class="preview-video-player"
                          ref="videoPlayer"
                          :options="{
                            sources: [{ type: 'video/mp4', src: preview_url[0] }],
                            poster: videoBackgroundPoster,
                            language: 'en',
                          }"
                          :playsinline="true"
                          customEventName="customstatechangedeventname"
                        />
                      </div>
                      <div v-else-if="getFileTypeFromUrl(preview_url[1]) == 'audio'">
                        <audio controls>
                          <source :src="preview_url[0]" type="audio/mpeg" />
                        </audio>
                      </div>

                      <v-img
                        contain
                        v-else-if="getFileTypeFromUrl(preview_url[0]) == 'image'"
                        height="300px"
                        width="500px"
                        :src="preview_url[0]"
                      ></v-img>

                      <PdfViewer
                        v-else-if="getFileTypeFromUrl(preview_url[0]) == 'pdf'"
                        height="300px"
                        width="500px"
                        :src="preview_url[0]"
                      />
                      <v-img
                        contain
                        v-else-if="getFileTypeFromUrl(preview_url[0]) == 'doc'"
                        height="300px"
                        width="500px"
                        src="@/assets/docx-image.png"
                      ></v-img>
                      <v-btn v-if="isAssetUploadRequired" primary @click="addToAsset"
                        >Add to asset</v-btn
                      >
                      <div class="align-download-center">
                        <a class="download-text-lp-form" :href="preview_url[0]" download
                          >Download</a
                        >
                      </div>
                    </v-col>
                    <v-col v-else md="8" style="display: flex; justify-content: center;">
                      <v-img src="@/assets/na.svg" height="250px" width="500px"></v-img>
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="input-card">
              <v-row class="preview-container">
                <div class="filename" v-if="preview_url[1]">
                  {{ getFileNameFromUrl(preview_url[1]) }}
                </div>
                <v-col cols="12" sm="3" md="12" class="brief-image-wrapper">
                  <div v-if="preview_url[1]">
                    {{ getFileNameFromUrl(preview_url[1]) }}
                    <video-player
                      v-if="getFileTypeFromUrl(preview_url[1]) == 'video'"
                      class="preview-video-player"
                      ref="videoPlayer"
                      :options="{
                        sources: [{ type: 'video/mp4', src: preview_url[1] }],
                        poster: videoBackgroundPoster,
                        language: 'en',
                      }"
                      :playsinline="true"
                      customEventName="customstatechangedeventname"
                    />
                    <div v-else-if="getFileTypeFromUrl(preview_url[1]) == 'audio'">
                      <audio controls>
                        <source :src="preview_url[1]" type="audio/mpeg" />
                      </audio>
                    </div>
                    <v-img
                      v-else-if="getFileTypeFromUrl(preview_url[1]) == 'image'"
                      height="300px"
                      width="450px"
                      :src="preview_url[1]"
                    ></v-img>

                    <PdfViewer
                      v-else-if="getFileTypeFromUrl(preview_url[1]) == 'pdf'"
                      height="300px"
                      width="400px"
                      :src="preview_url[1]"
                    />
                    <v-img
                      v-else-if="getFileTypeFromUrl(preview_url[1]) == 'doc'"
                      height="300px"
                      width="400px"
                      src="@/assets/docx-image.png"
                    ></v-img>
                  </div>
                  <v-file-input
                    v-if="!useAssetVault && !preview_url[+isAssetUploadRequired]"
                    refs="fileInput"
                    dense
                    outlined
                    hide-details
                    prepend-icon="mdi-image"
                    color="accent"
                    show-size
                    :accept="fileAcceptType"
                    :key="image_url"
                    :rules="[
                      (v) =>
                        !isRequired ||
                        !isAssetUploadRequired ||
                        (imageUrl[+isAssetUploadRequired] || '').length > 0 ||
                        Errors.REQUIRED_FIELD,
                    ]"
                    :label="label"
                    :placeholder="imageUrl[+isAssetUploadRequired]"
                    @change="onChange"
                    :clearable="false"
                    :disabled="(imageUrl[+isAssetUploadRequired] || '').length > 0"
                    :loading="loadingStatus"
                    :error-messages="errorMessages"
                  ></v-file-input>
                  <AssetVaultUpload
                    v-if="useAssetVault && !preview_url[+isAssetUploadRequired]"
                    :key="`asset-vault-${generateIdentifier}`"
                    :preset="CA_QUESTION_PRESET"
                    :appName="CA_APP_NAME"
                    :assetResponseHook="onChange"
                    :placeholder="`Supported ${
                      isAssetUploadRequired
                        ? '(png / jpeg / mp3 / mp4)'
                        : '(png / jpeg / doc / docx / pdf / mp3 / mp4)'
                    } `"
                    :acceptedFileTypes="
                      isAssetUploadRequired
                        ? ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg']
                        : [
                            'application/pdf',
                            'image/png',
                            'image/jpeg',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/msword',
                            'video/mp4',
                            'audio/mpeg',
                          ]
                    "
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-dialog>
      </template>
    </v-form>
  </div>
</template>

<script>
import Errors from '@/constants/Errors';
import { getFileNameFromUrl, getFileTypeFromUrl } from '@/functions/global';
import videoBackgroundPoster from '@/assets/video-arrow-overlay.png';
import AssetPreviewDialog from '@/components/AssetPreviewDialog';
import AssetUploadInProgressStatus from '@/components/AssetUploadInProgressStatus';
import { showErrorNotification } from '@/utils';
import AssetVaultUpload from '@/components/AssetVault/AssetVaultUpload';
import { CA_APP_NAME, CA_QUESTION_PRESET } from '@/constants/centralizedAssetContants.js';
import PdfViewer from '@/components/common/PdfViewer';

export default {
  name: 'FileInput',
  components: {
    AssetPreviewDialog,
    AssetUploadInProgressStatus,
    AssetVaultUpload,
    PdfViewer,
  },
  data() {
    return {
      Errors,
      imageUrl: [],
      preview_url: [],
      showPreviewDialog: false,
      videoBackgroundPoster,
      errorMessages: [],
      fileInputIdentifier: '',
      CA_APP_NAME,
      CA_QUESTION_PRESET,
      showCentralizedAssetUploaderDialog: false,
    };
  },
  computed: {
    fileInputValidator() {
      if (this.isRequired && this.isAssetUploadRequired) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getFileNameFromUrl,
    getFileTypeFromUrl,
    addToAsset() {
      this.$emit('addBriefToAsset', this.previewUrlBrief, this.image_url_brief);
    },
    generateIdentifier() {
      if (!this.fileInputIdentifier.length) {
        this.fileInputIdentifier = 'file-input' + Math.random().toString(36).slice(2);
      }
      return this.fileInputIdentifier;
    },
    handleAssetReplaceAction() {
      console.log('----handleAssetReplaceAction----');
    },
    openPreviewDialog(e) {
      if (this.isAssetUploadRequired && this.previewUrlBrief !== '') {
        e.preventDefault();
        this.showPreviewDialog = true;
      } else {
        if (this.useAssetVault) {
          this.openCentralisedAssetWindow();
        }
      }
    },
    stringUrlToArray() {
      this.imageUrl[0] = this.image_url_brief;
      this.preview_url[0] = this.previewUrlBrief;
      if (this.isAssetUploadRequired) {
        this.imageUrl[1] = this.image_url;
        this.preview_url[1] = this.previewUrl;
      }
      this.$forceUpdate();
    },
    onChange(file) {
      let errorString;
      for (let validator of this.fileValidators) {
        let result = validator(file);
        if (typeof result == 'string') {
          errorString = result;
          break;
        }
      }

      if (errorString) {
        this.errorMessages = [errorString];
        showErrorNotification(errorString);
      } else {
        this.errorMessages = [];
        this.fileChangeHandler(file);
        if (!file && this.isAssetApproved) {
          this.$emit('addBriefToAsset', '', '', false);
        }
      }
    },
    openCentralisedAssetWindow() {
      this.showCentralizedAssetUploaderDialog = true;
    },
    onUpdateAssetAsFinal(status) {
      if (this.canFinalizeAsset) {
        if (status) {
          this.$emit('addBriefToAsset', this.previewUrlBrief, this.image_url_brief, true);
        } else {
          this.$emit('addBriefToAsset', '', '', false);
        }
      }
    },
  },
  watch: {
    previewUrl: function () {
      this.stringUrlToArray();
    },
    previewUrlBrief: function () {
      this.stringUrlToArray();
    },
  },
  props: {
    image_url: {
      type: String,
      required: false,
      default: '',
    },
    image_url_brief: {
      type: String,
      required: false,
      default: '',
    },
    previewUrl: {
      type: String,
      required: false,
      default: '',
    },
    previewUrlBrief: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      required: false,
      default: false,
    },
    imagePlaceholder: {
      type: String,
      required: false,
      default: 'image',
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileAcceptType: {
      type: String,
      required: false,
      default: null,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDiffChanged: {
      type: Boolean,
      required: false,
      default: true,
    },
    diffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    isAssetApproved: {
      type: Boolean,
      default: false,
    },
    canFinalizeAsset: {
      type: Boolean,
      default: false,
    },
    useAssetVault: {
      type: Boolean,
      default: false,
    },
    ApproveAssetAndEmitResult: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.stringUrlToArray();
  },
};
</script>
<style lang="scss" scoped>
.filename {
  text-align: center;
  color: #000;
}
.download-text-lp-form {
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}
.download-text-lp-form:hover {
  text-decoration: none;
}
.lpForm-asset-preview-wrapper {
  display: flex;

  .input-card {
    width: 50%;
    margin: 0 5px;
    position: relative;
    display: flex;
    align-items: center;
  }
}
.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headline {
  max-width: 500px;
}
.brief-headline {
  max-width: 500px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.selected-box svg {
  color: #37b074;
  background: white;
  border: green;
  transform: translateX(5px);
}
.not-selected-box .v-input--selection-controls__input {
  background: white;
  transform: translateX(5px);
}
</style>
