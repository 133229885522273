<template>
  <div class="action-program-wrapper">
    <!--Action Program 1 -->
    <v-expansion-panel
      v-if="schema.actionProgram1.show && showProgram1"
      class="expansion-panel"
      key="ActionProgram1Section"
    >
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.ACTION_PROGRAM_1"
          :types="
            lessonPlanData && lessonPlanData.actionProgram1
              ? lessonPlanData.actionProgram1.types
              : []
          "
          :time="
            lessonPlanData && lessonPlanData.actionProgram1
              ? lessonPlanData.actionProgram1.time
              : ''
          "
          :canEditType="auth.canSaveProjectLP"
          @update-type-of-block-to-state="
            (val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_1, val)
          "
          :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_1, e)"
          :isNotReadOnly="auth.canSaveProjectLP"
          :displayTime="true"
          :canEditTime="auth.canSaveProjectLP"
          @set-timer-input="
            (val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_1, val)
          "
          :allowedTimeValues="schema.actionProgram1.allowedTimes"
          :schema="schema"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content">
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.STANDARD_LP_ACTION"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION_COMPONENT"
          subPath="actionProgram1"
          :initialActive="isSlideLayoutEnabled('actionProgram1.instructions.0', lessonPlanData)"
          :showLegacyComponentSwitcher="schema.actionProgram1.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show']"
        >
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="auth.canSaveProjectLP"
              subPath="actionProgram1.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.actionProgram1.configurableMultiSlide.maxSlidesCount"
            />
          </template>
          <template #false-slot>
            <GenericActionSection
              :lessonPlanData="lessonPlanData"
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :actionSignedUrls="signedUrls.actionProgram1 || {}"
              :actionDiff="diff.actionProgram1 || {}"
              :canShowDiffIcon="canShowDiffIcon"
              :isNotReadOnly="auth.canSaveProjectLP"
              :acceptableFileType="acceptableFileType"
              :fileValidators="fileValidators"
              :canAppproveAsset="canAppproveAsset"
              :schema="schema.actionProgram1"
              :canShowInstructionBreakCheckbox="true"
              :canShowInstructionTeacherTip="true"
            />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Action Program 1 END -->

    <!--Action Program 2 -->
    <v-expansion-panel
      v-if="schema.actionProgram1.show && showProgram2"
      class="expansion-panel"
      key="ActionProgram2Section"
      :readonly="!lessonPlanData.actionProgram2.show"
    >
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.ACTION_PROGRAM_2"
          :types="
            lessonPlanData && lessonPlanData.actionProgram2
              ? lessonPlanData.actionProgram2.types
              : []
          "
          :time="
            lessonPlanData && lessonPlanData.actionProgram2
              ? lessonPlanData.actionProgram2.time
              : ''
          "
          :canEditType="auth.canSaveProjectLP"
          @update-type-of-block-to-state="
            (val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_2, val)
          "
          :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_2, e)"
          :isNotReadOnly="auth.canSaveProjectLP"
          :displayTime="lessonPlanData.actionProgram2.show"
          :displayTrailing="lessonPlanData.actionProgram2.show"
          :displayComments="lessonPlanData.actionProgram2.show"
          :headerCheck="true"
          headerCheckText="Allow Authoring"
          :canEditTime="auth.canSaveProjectLP"
          @set-timer-input="
            (val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ACTION_PROGRAM_2, val)
          "
          :allowedTimeValues="schema.actionProgram2.allowedTimes"
          :schema="schema"
          :initialHeaderCheckValue="lessonPlanData.actionProgram2.show"
          @headerCheckboxChange="(val) => (lessonPlanData.actionProgram2.show = val)"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content
        v-show="lessonPlanData.actionProgram2.show"
        class="section-title-content"
      >
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.STANDARD_LP_ACTION"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION_COMPONENT"
          subPath="actionProgram2"
          :initialActive="isSlideLayoutEnabled('actionProgram2.instructions.0', lessonPlanData)"
          :showLegacyComponentSwitcher="schema.actionProgram2.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show']"
        >
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="auth.canSaveProjectLP"
              subPath="actionProgram2.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.actionProgram2.configurableMultiSlide.maxSlidesCount"
            />
          </template>
          <template #false-slot>
            <GenericActionSection
              :lessonPlanData="lessonPlanData"
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :actionSignedUrls="signedUrls.actionProgram2"
              :actionDiff="diff.actionProgram2 || {}"
              :canShowDiffIcon="canShowDiffIcon"
              :isNotReadOnly="auth.canSaveProjectLP"
              :acceptableFileType="acceptableFileType"
              :fileValidators="fileValidators"
              :canAppproveAsset="canAppproveAsset"
              :schema="schema.actionProgram2"
              :canShowInstructionBreakCheckbox="true"
              :canShowInstructionTeacherTip="true"
            />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Action Program 2 END -->
  </div>
</template>
<script>
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import ExpansionPanelHeader from '@/components/lessonPlanTemplate/ExpansionPanelHeader';
import GenericActionSection from '@/components/lessonPlanTemplate/commonLpComponents/GenericActionSection';
import { COMMENT_SECTION_NAMES_HASH } from '@/constants/LessonPlan';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled } from '@/utils/slideLayoutHelpers.js';

export default {
  name: 'ActionProgram',
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
      FILE_UPLOADING_STATUS,
      COMMENT_SECTION_NAMES_HASH,
      COMPONENT_TO_DS_MAP,
    };
  },
  components: {
    ExpansionPanelHeader,
    GenericActionSection,
    MultiSlideLayout,
    XSRightSwitch,
  },
  methods: {
    isSlideLayoutEnabled,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    fileValidators: {
      type: Array,
      default: () => [],
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    diff: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => ({}),
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    showProgram1: {
      type: Boolean,
      default: false,
    },
    showProgram2: {
      type: Boolean,
      default: false,
    },
    updateTimeOfBlockToState: {
      type: Function,
      required: true,
    },
    updateTypeOfBlockToState: {
      type: Function,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.action-program-wrapper {
  width: 100%;
  padding: 0;
  margin: 16px 0 0 0;
}
</style>
