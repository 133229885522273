<template>
  <div>
    <v-row>
      <v-col v-if="!isViewOnly && !hideInfoCard" cols="12">
        <InfoCard :lpType="lpType" />
      </v-col>
      <v-col v-if="isVirtualQuestion">
        <QuestionFilter
          :questionpurposeItems="questionpurposeItems"
          :selectedQuestionpurposeItems="selectedQuestionpurposeItems"
          @onQuestionPurposeChange="onQuestionPurposeChange"
          :lessonPlanItems="lessonPlansFilterData"
          :selectedLessonPlanItems="selectedLessonPlanItems"
          @onLessonPlanFilterChange="onLessonPlanFilterChange"
          :difficultyLevelItems="difficultyLevelsFilterData"
          :selectedDifficultyLevelItems="selectedDifficultyLevelItems"
          @onDifficultyLevelFilterChange="onDifficultyLevelFilterChange"
          @onViewChange="
            (value) => {
              currentView = value;
            }
          "
          :questionPropertiesToIncludeInDownload="questionPropertiesToIncludeInDownload"
          :downloadQuestions="downloadQuestionsHandler"
          :isReadOnly="!options.isAssetUploadRequired"
          @onChangeFileType="
            (value) => {
              fileType = value;
            }
          "
        />
      </v-col>
      <v-col v-if="!isViewOnly && !showCreateQuestionAsBottomBar" cols="12" class="mt-6 mb-6">
        <CreateQuestionCard
          :data="questionData"
          :isReadOnly="options.isReadOnly"
          :lpType="lpType"
        />
      </v-col>
      <v-col cols="12" v-if="isDeatailedView">
        <DynamicScroller
          :items="groupedQuestionArray"
          :buffer="bufferSize"
          :minItemSize="300"
          class="scroller"
          keyField="key"
          :simpleArray="true"
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :size-dependencies="[]"
              :data-index="index"
            >
              <Var :datum="item['value']" v-slot="{ datum }">
                <Var
                  :updatedOptions="getOptionsBasedOnQuestionData(datum)"
                  v-slot="{ updatedOptions }"
                >
                  <XSQuestionRenderer
                    :questionIndex="index"
                    :datum="datum"
                    :saveForm="saveForm"
                    :options="updatedOptions"
                    :toggleComments="toggleComments"
                    :isViewOnly="isViewOnly"
                    :canShowActionButtons="canShowActionButtons"
                    :isSchoolProgramQuestion="isSchoolProgramQuestion"
                    :showMinimalDetails="showMinimalDetails"
                    :shouldEmitDeleteAction="shouldEmitDeleteAction"
                    :questionMetaInfo="questionMetaInfo || {}"
                    :boards="boards"
                    :blockIdToBlockBIdMapping="blockIdToBlockBIdMapping"
                    :questionDataFromState="questionDataFromState"
                    :getAllBlocksForSelectedProductBoardStandardSubjectBlockId="
                      getAllBlocksForSelectedProductBoardStandardSubjectBlockIdMethod
                    "
                    :convertIndividualToGroupQuestion="convertIndividualToGroupQuestion"
                    :openQuestionDialog="openQuestionDialog"
                    :deleteQuestion="deleteQuestion"
                    :openGroupQuestionDialog="openGroupQuestionDialog"
                    :deleteGroupQuestion="deleteGroupQuestion"
                    :lpType="lpType"
                    :questionPurposeDisplayText="getQuestionPurposeDisplayText"
                    @question-preview-cliked="(e) => $emit('question-preview-cliked', e)"
                    @remove-question-clicked="(e) => $emit('remove-question-clicked', e)"
                    :getAnswerCriteria="getAnswerCriteria"
                  />
                </Var>
              </Var>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </v-col>
      <v-col cols="12" v-else>
        <div v-for="(key, index) of Object.keys(filteredQuestions)" :key="index">
          <div v-if="Array.isArray(filteredQuestions[key])" class="list-view-question-wrapper">
            <SingleGroupQuestionView
              :isSelected="filteredQuestions[key].some((e) => e.isSelected)"
              :index="index + 1"
              :questionMetaInfo="questionMetaInfo"
              :lessonPlans="lessonPlans"
              :data="filteredQuestions[key]"
              :canSelectItem="true"
              @onSelectQuestion="
                (isSelected, data) => {
                  toggleGroupQuestionSelection(isSelected, data);
                }
              "
            />
          </div>
          <div v-else class="list-view-question-wrapper">
            <SingleQuestionView
              :isSelected="filteredQuestions[key].isSelected"
              :index="index + 1"
              :questionMetaInfo="questionMetaInfo"
              :lessonPlans="lessonPlans"
              :data="filteredQuestions[key]"
              :canSelectItem="true"
              @onSelectQuestion="
                (isSelected, data) => {
                  toggleQuestionSelection(isSelected, data);
                }
              "
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mt-6 mb-6" v-if="showCreateQuestionAsBottomBar">
        <CreateQuestionCardWithGreenButtons
          :data="questionData"
          :isReadOnly="options.isReadOnly"
          :lpType="lpType"
          :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          :selectedEntityType="options.selectedEntityType"
        />
      </v-col>
    </v-row>
    <QuestionDialog
      v-if="showDialog"
      :saveForm="saveForm"
      :options="options"
      :canAppproveAsset="canAppproveAsset"
      :lpType="lpType"
      :lessonPlans="lessonPlans"
      :isLanguageSubject="isLanguageSubject"
      :isSchoolProgramQuestion="isSchoolProgramQuestion"
      :modifyQuestionDataHash="modifyQuestionDataHash"
      :checkIfOptionsWillNotBeRetained="checkIfOptionsWillNotBeRetained"
      :showMetaFields="showMetaFields"
      :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
      :allowedQuestionTypes="entityAllowedQuestionTypes[options.selectedEntityType]"
    />
    <QuestionGroupDialog
      v-if="showGroupQuestionDialog"
      :saveForm="saveForm"
      :options="options"
      :canAppproveAsset="canAppproveAsset"
      :lpType="lpType"
      :lessonPlans="lessonPlans"
      :isLanguageSubject="isLanguageSubject"
      :isSchoolProgramQuestion="isSchoolProgramQuestion"
      :modifyQuestionDataHash="modifyQuestionDataHash"
      :checkIfOptionsWillNotBeRetained="checkIfOptionsWillNotBeRetained"
      :showMetaFields="showMetaFields"
      :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
    />
    <BlockFilterDialog
      :filterOptions="{ boards, products, standards, subjects }"
      :showblockFilter="showblockFilter"
      :sourceBlockLessons="lessonPlans"
      :saveForm="saveForm"
    />
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import InfoCard from '@/components/contentEntry/questionComponents/InfoCard.vue';
import CreateQuestionCard from '@/components/contentEntry/questionComponents/CreateQuestionCard.vue';
import QuestionDialog from '@/components/contentEntry/questionComponents/QuestionDailog.vue';
import QuestionGroupDialog from '@/components/contentEntry/questionComponents/QuestionGroupDialog.vue';
import QuestionFilter from '@/components/contentEntry/questionComponents/QuestionFilter.vue';
import { getAssetUploadRequiredStatus } from '@/utils';
import { LP_TYPES_HASH, LANGUAGE_SUBJECT_SHORT_NAMES } from '@/constants/common';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import SingleQuestionView from '@/components/Questions/SingleQuestionView';
import SingleGroupQuestionView from '@/components/Questions/SingleGroupQuestionView';
import Var from '@/components/Var';
import _ from 'lodash';
import BlockFilterDialog from '@/components/contentEntry/questionComponents/BlockFilterDialog.vue';
import { BLOCKED_BOARDS, BLOCKED_PRODUCTS } from '@/constants/LessonPlan';
import CreateQuestionCardWithGreenButtons from '@/components/contentEntry/questionComponents/CreateQuestionCardWithGreenButtons.vue';
import { XSQuestionRenderer } from '@xseededucation/vue-question-display-component';
import { getQuestionPurposeDisplayText } from '@/utils/questionUtils.js';
export default {
  name: 'Questions',
  data() {
    return {
      isInitialDataLoaded: false,
      questionsOprogramProject,
      isDeatailedView: true,
      currentView: 'detailedView',
      selectedQuestionpurposeItems: null,
      selectedQuestionIds: [],
      filteredQuestions: {},
      questionPropertiesToIncludeInDownload: {
        academicYear: true,
        time: true,
        difficulty: true,
        skill: true,
        subSkill: true,
        languageSkill: true,
        lessonPlan: true,
        questionPurpose: true,
      },
      fileType: 'pdf',
      lessonPlansFilterData: null,
      selectedLessonPlanItems: [],
      difficultyLevelsFilterData: null,
      selectedDifficultyLevelItems: [],
      entityAllowedQuestionTypes: {
        FEEDBACK_TEST: [
          questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
          questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ,
          questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF,
          questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
        ],
      },
    };
  },
  created() {
    this.getProductsBoardsStandardsSubjects();
  },
  methods: {
    ...mapActions([
      'setFilteredQuestion',
      'downloadQuestions',
      'getProductsBoardsStandardsSubjects',
      'deleteContentEntryGroupQuestion',
      'questionForceSaveWithData',
      'getAllBlocksForSelectedProductBoardStandardSubjectBlockId',
      'instantiateContentEntryProjectData',
      'deleteContentEntryQuestion',
    ]),
    ...mapMutations([
      'SET_QUESTION_DATA',
      'RESET_QUESTION_DATA',
      'SET_QUESTION_CURRENT_STAGE',
      'RESTORE_LOCAL_STORAGE_DATA',
      'SET_SHOW_GROUP_QUESTION_DIALOG',
      'SET_CURRENT_QUESTION_DIALOG_DATA',
      'SET_SHOW_QUESTION_DIALOG',
    ]),
    getAssetUploadRequiredStatus,
    getQuestionPurposeDisplayText,
    openGroupQuestionDialog(data) {
      this.SET_SHOW_GROUP_QUESTION_DIALOG(true);
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: _.cloneDeep(data) });
    },
    openQuestionDialog(data) {
      this.SET_SHOW_QUESTION_DIALOG(true);
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: _.cloneDeep(data) });
    },
    deleteGroupQuestion(questionGroupId) {
      this.deleteContentEntryGroupQuestion({
        questionGroupId,
        onSuccess: () => {
          this.questionForceSaveWithData({
            callback: this.saveForm,
            validateForm: false,
            showLoader: true,
          });
        },
      });
    },
    deleteQuestion(questionId) {
      this.deleteContentEntryQuestion({
        questionId: questionId,
        onSuccess: () => {
          this.questionForceSaveWithData({
            validateForm: false,
            showLoader: true,
            callback: this.saveForm,
          });
        },
      });
    },
    getOptionsBasedOnQuestionData(questionData) {
      if (!questionData || !this.forceUseAssetBasedOnQuestionProjectCurrentStageData)
        return this.options;

      let updatedOptions = this.options;
      let projectCurrentStage = Array.isArray(questionData)
        ? questionData[0].projectCurrentStage
        : questionData.projectCurrentStage;

      if (this.forceUseAssetBasedOnQuestionProjectCurrentStageData && projectCurrentStage) {
        updatedOptions.isAssetUploadRequired = getAssetUploadRequiredStatus(projectCurrentStage);
      }

      return updatedOptions;
    },
    getAnswerCriteria(data) {
      if (Array.isArray(data)) {
        return (data.find((e) => e.answerCriteria != null) || {}).answerCriteria || [];
      }

      return data.answerCriteria || [];
    },
    isQuestionPurposeFilterAvailable() {
      return this.selectedQuestionpurposeItems && this.selectedQuestionpurposeItems.length > 0;
    },
    isLessonsFilterAvailable() {
      return this.selectedLessonPlanItems && this.selectedLessonPlanItems.length > 0;
    },
    isDifficultyFilterAvailable() {
      return this.selectedDifficultyLevelItems && this.selectedDifficultyLevelItems.length > 0;
    },
    updateFilterQuestionsBasedOnSelectedFilters() {
      const questions = {};

      Object.keys(this.groupedQuestions).forEach((questionId) => {
        const questionData = this.groupedQuestions[questionId];
        const isGroupQuestion = Array.isArray(questionData);
        let isFiltered = false;

        if (isGroupQuestion) {
          const isQuestionPurposePresent = this.isQuestionPurposeFilterAvailable()
            ? questionData.some((e) => {
                return this.selectedQuestionpurposeItems.includes(e.questionPurpose);
              })
            : true;

          const isMatchesWithSelectedLessonsFilter = this.isLessonsFilterAvailable()
            ? questionData.some((e) => {
                return this.selectedLessonPlanItems.includes(e.lessonPlanLibraryId);
              })
            : true;

          const isMatchesWithSelectedDifficultyFilter = this.isDifficultyFilterAvailable()
            ? questionData.some((e) => {
                return this.selectedDifficultyLevelItems.includes(e.difficulty);
              })
            : true;

          isFiltered =
            isQuestionPurposePresent &&
            isMatchesWithSelectedLessonsFilter &&
            isMatchesWithSelectedDifficultyFilter;
        } else {
          const isQuestionPurposePresent = this.isQuestionPurposeFilterAvailable()
            ? this.selectedQuestionpurposeItems.includes(questionData.questionPurpose)
            : true;

          const isMatchesWithSelectedLessonsFilter = this.isLessonsFilterAvailable()
            ? this.selectedLessonPlanItems.includes(questionData.lessonPlanLibraryId)
            : true;

          const isMatchesWithSelectedDifficultyFilter = this.isDifficultyFilterAvailable()
            ? this.selectedDifficultyLevelItems.includes(questionData.difficulty)
            : true;

          isFiltered =
            isQuestionPurposePresent &&
            isMatchesWithSelectedLessonsFilter &&
            isMatchesWithSelectedDifficultyFilter;
        }

        if (isFiltered) {
          questions[questionId] = questionData;
        }
      });

      this.filteredQuestions = questions;
    },
    onQuestionPurposeChange(val) {
      this.selectedQuestionpurposeItems = val;
      this.selectedLessonPlanItems = [];
      this.selectedDifficultyLevelItems = [];

      this.updateLessonPlansFilterData();
      this.updateDifficultyLevelsFilterData();
    },
    updateLessonPlansFilterData() {
      const lessonPlansIdSetToBeFiltered = new Set([]);

      for (const questionId in this.groupedQuestions) {
        const questionData = this.groupedQuestions[questionId];
        const isGroupQuestion = Array.isArray(questionData);

        if (isGroupQuestion) {
          for (const _singleQuestion of questionData) {
            if (this.selectedQuestionpurposeItems.includes(_singleQuestion.questionPurpose)) {
              lessonPlansIdSetToBeFiltered.add(_singleQuestion.lessonPlanLibraryId);
            }
          }
        } else {
          if (this.selectedQuestionpurposeItems.includes(questionData.questionPurpose)) {
            lessonPlansIdSetToBeFiltered.add(questionData.lessonPlanLibraryId);
          }
        }
      }
      const lessonPlansIdToBeFiltered = Array.from(lessonPlansIdSetToBeFiltered);
      this.lessonPlansFilterData = this.lessonPlans
        .filter((_lp) => lessonPlansIdToBeFiltered.includes(_lp.library_id))
        .map((_lp) => {
          return { label: _lp.name, value: _lp.library_id };
        });
    },
    onLessonPlanFilterChange(val) {
      this.selectedLessonPlanItems = val;
      this.selectedDifficultyLevelItems = [];

      this.updateFilterQuestionsBasedOnSelectedFilters();
      this.updateDifficultyLevelsFilterData();
    },
    updateDifficultyLevelsFilterData() {
      let difficultyLevelsFilterDataCopy = new Set([]);
      for (const questionId in this.groupedQuestions) {
        const questionData = this.groupedQuestions[questionId];
        const isGroupQuestion = Array.isArray(questionData);

        if (isGroupQuestion) {
          for (const _singleQuestion of questionData) {
            if (this.selectedLessonPlanItems.includes(_singleQuestion.lessonPlanLibraryId)) {
              difficultyLevelsFilterDataCopy.add(_singleQuestion.difficulty);
            }
          }
        } else {
          if (this.selectedLessonPlanItems.includes(questionData.lessonPlanLibraryId)) {
            difficultyLevelsFilterDataCopy.add(questionData.difficulty);
          }
        }
      }

      this.difficultyLevelsFilterData = Array.from(difficultyLevelsFilterDataCopy).map((_text) => {
        return {
          label: this.getFilterLabel(_text),
          value: _text,
        };
      });
    },
    onDifficultyLevelFilterChange(val) {
      this.selectedDifficultyLevelItems = val;

      this.updateFilterQuestionsBasedOnSelectedFilters();
    },
    async downloadQuestionsHandler() {
      const projectId = this.$route.params.projectId;
      let questionIds = [];
      Object.values(this.filteredQuestions).forEach((questionData) => {
        const isGroupQuestion = Array.isArray(questionData);
        if (isGroupQuestion) {
          const isSelected = questionData.some((e) => e.isSelected);
          if (isSelected) {
            questionIds = questionIds.concat(questionData.map((e) => e._id));
          }
        } else {
          if (questionData.isSelected) {
            questionIds.push(questionData._id);
          }
        }
      });
      const questionPropertiesToIncludeInDownload = this.questionPropertiesToIncludeInDownload;
      const fileType = this.fileType;
      this.downloadQuestions({
        projectId,
        questionIds,
        questionPropertiesToIncludeInDownload,
        downloadExt: fileType,
      });
    },
    toggleQuestionSelection(isSelected, data) {
      this.filteredQuestions[data._id].isSelected = isSelected;
    },
    toggleGroupQuestionSelection(isSelected, data) {
      const { questionGroupId } = data[0];
      this.filteredQuestions[questionGroupId].forEach((element) => {
        element.isSelected = isSelected;
      });
    },
    getFilterLabel(str) {
      return _.startCase(_.toLower(str));
    },
    modifyQuestionDataHash(oldQuestion, newQuestion) {
      const { questionData: oldQuestionData, questionType: oldQuestionType } = oldQuestion;
      const { questionData: newQuestionData, questionType: newQuestionType } = newQuestion;

      const isOldQuestionMCQ =
        oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ;
      const isOldQuestionMSQ =
        oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ;
      const isOldQuestionATF =
        oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF;
      const isOldQuestionSA = oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA;
      const isOldQuestionLA = oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA;
      const isOldQuestionMTF =
        oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF;
      const isOldQuestionFIB =
        oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB;
      const isOldQuestionTF = oldQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF;

      const isNewQuestionMCQ =
        newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ;
      const isNewQuestionMSQ =
        newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ;
      const isNewQuestionATF =
        newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF;
      const isNewQuestionMTF =
        newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF;
      const isNewQuestionSA = newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA;
      const isNewQuestionLA = newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA;
      const isNewQuestionFIB =
        newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB;
      const isNewQuestionTF = newQuestionType === questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF;

      if ((isOldQuestionMCQ || isOldQuestionMSQ) && (isNewQuestionMCQ || isNewQuestionMSQ)) {
        newQuestionData.options = oldQuestionData.options;
        // When MSQ question (with multiple correct options) converted to MCQ question, All options will be set to wrong answer. User need to update the correct answer. - DT-3382
        if (isOldQuestionMSQ && isNewQuestionMCQ) {
          newQuestionData.options.forEach((option) => {
            option.isCorrectAnswer = false;
          });
        }
      } else if ((isOldQuestionMCQ || isOldQuestionMSQ) && isNewQuestionATF) {
        let sequence = 1;
        newQuestionData.optionValues = [];

        oldQuestionData.options.forEach((option) => {
          newQuestionData.optionValues.push({
            optionText: option.optionText,
            sequence: sequence,
          });
          sequence++;
        });
      } else if (isOldQuestionATF && (isNewQuestionMCQ || isNewQuestionMSQ)) {
        newQuestionData.options = [];

        oldQuestionData.optionValues.forEach((option) => {
          newQuestionData.options.push({
            optionText: option.optionText,
            isCorrectAnswer: false,
            misconception: '',
          });
        });
      } else if (isOldQuestionFIB) {
        // FIB → ANY (MCQ MSQ AIO )
        if (isNewQuestionMCQ || isNewQuestionMSQ) {
          newQuestionData.options = [];
          if (oldQuestionData.blanks.length > 0) {
            oldQuestionData.blanks.forEach((blank) => {
              blank.correctAnswers.forEach((option) => {
                newQuestionData.options.push({
                  optionText: option.text,
                  isCorrectAnswer: false,
                  misconception: '',
                });
              });
            });
          }
          if (oldQuestionData.distractors) {
            oldQuestionData.distractors.forEach((distractor) => {
              newQuestionData.options.push({
                optionText: distractor.text,
                isCorrectAnswer: false,
                misconception: '',
              });
            });
          }
        } else if (isNewQuestionATF) {
          let sequence = 1;
          newQuestionData.optionValues = [];

          if (oldQuestionData.blanks.length > 0) {
            oldQuestionData.blanks.forEach((blank) => {
              blank.correctAnswers.forEach((option) => {
                newQuestionData.optionValues.push({
                  optionText: option.text,
                  sequence: sequence,
                });
                sequence++;
              });
            });
          }
          if (oldQuestionData.distractors.length > 0) {
            oldQuestionData.distractors.forEach((distractor) => {
              newQuestionData.optionValues.push({
                optionText: distractor.text,
                sequence: sequence,
              });
            });
          }
        } else if (isNewQuestionMTF) {
          let sequence = 1;
          newQuestionData.optionKeys = [];
          newQuestionData.optionValues = [];

          if (oldQuestionData.blanks.length > 0) {
            oldQuestionData.blanks.forEach((blank) => {
              blank.correctAnswers.forEach((option) => {
                newQuestionData.optionKeys.push({
                  optionText: option.text,
                  sequence: sequence,
                });
                newQuestionData.optionValues.push({
                  optionText: '',
                  sequence: sequence,
                });
                sequence++;
              });
            });
          }
          if (oldQuestionData.distractors.length > 0) {
            oldQuestionData.distractors.forEach((distractor) => {
              newQuestionData.optionKeys.push({
                optionText: distractor.text,
                sequence: sequence,
              });
            });
          }
        } else if (isNewQuestionSA || isNewQuestionLA) {
          newQuestionData.answerGuideText = oldQuestionData.answerGuideText;
        }
      } else if ((isOldQuestionSA || isOldQuestionLA) && (isNewQuestionSA || isNewQuestionLA)) {
        newQuestionData.answerGuideText = oldQuestionData.answerGuideText;
        newQuestionData.allowedResponseTypes = oldQuestionData.allowedResponseTypes;
      } else if ((isOldQuestionSA || isOldQuestionLA) && (isNewQuestionFIB || isNewQuestionTF)) {
        newQuestionData.answerGuideText = oldQuestionData.answerGuideText;
      } else if (isOldQuestionMTF) {
        //  MTF -> ANY
        if (isNewQuestionMCQ || isNewQuestionMSQ) {
          newQuestionData.options = [];
          for (let i = 0; i < oldQuestionData.optionKeys.length; i++) {
            const columnA = JSON.parse(oldQuestionData.optionKeys[i].optionText);
            const columnB = JSON.parse(oldQuestionData.optionValues[i].optionText);

            const concatedContents = {
              ...columnA,
              content: _.concat(columnA.content, columnB.content),
            };

            newQuestionData.options.push({
              optionText: JSON.stringify(concatedContents),
              isCorrectAnswer: false,
              misconception: '',
            });
          }
        } else if (isNewQuestionATF) {
          newQuestionData.optionValues = [];
          for (let i = 0; i < oldQuestionData.optionKeys.length; i++) {
            const columnA = JSON.parse(oldQuestionData.optionKeys[i].optionText);
            const columnB = JSON.parse(oldQuestionData.optionValues[i].optionText);

            const concatedContents = {
              ...columnA,
              content: _.concat(columnA.content, columnB.content),
            };
            newQuestionData.optionValues.push({
              optionText: JSON.stringify(concatedContents),
              sequence: i + 1,
            });
          }
        }
      } else if (isNewQuestionMTF) {
        newQuestionData.optionKeys = [];
        newQuestionData.optionValues = [];

        let sequence = 1;
        if (isOldQuestionMCQ || isOldQuestionMSQ) {
          oldQuestionData.options.forEach((option) => {
            newQuestionData.optionKeys.push({
              optionText: option.optionText,
              sequence: sequence,
            });
            newQuestionData.optionValues.push({
              optionText: '',
              sequence: sequence,
            });
            sequence++;
          });
        } else if (isOldQuestionATF) {
          oldQuestionData.optionValues.forEach((option) => {
            newQuestionData.optionKeys.push({
              optionText: option.optionText,
              sequence: sequence,
            });
            newQuestionData.optionValues.push({
              optionText: '',
              sequence: sequence,
            });
            sequence++;
          });
        }
      } else if (isOldQuestionTF && (isNewQuestionSA || isNewQuestionLA)) {
        newQuestionData.answerGuideText = oldQuestionData.answerGuideText;
      }
    },
    checkIfOptionsWillNotBeRetained(oldQuestionType, newQuestionType) {
      const {
        ACRONYM_QUESTION_TYPE_MCQ,
        ACRONYM_QUESTION_TYPE_MSQ,
        ACRONYM_QUESTION_TYPE_ATF,
        ACRONYM_QUESTION_TYPE_SA,
        ACRONYM_QUESTION_TYPE_LA,
        ACRONYM_QUESTION_TYPE_MTF,
        ACRONYM_QUESTION_TYPE_FIB,
        ACRONYM_QUESTION_TYPE_TF,
      } = questionsOprogramProject;

      const notAllowedConversionQuestionTypesHash = {
        [ACRONYM_QUESTION_TYPE_MCQ]: [
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_TF,
        ],
        [ACRONYM_QUESTION_TYPE_MSQ]: [
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_TF,
        ],
        [ACRONYM_QUESTION_TYPE_ATF]: [
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_TF,
        ],
        [ACRONYM_QUESTION_TYPE_MTF]: [
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_TF,
        ],
        [ACRONYM_QUESTION_TYPE_FIB]: [
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_TF,
        ],
        [ACRONYM_QUESTION_TYPE_TF]: [
          ACRONYM_QUESTION_TYPE_MCQ,
          ACRONYM_QUESTION_TYPE_MSQ,
          ACRONYM_QUESTION_TYPE_ATF,
          ACRONYM_QUESTION_TYPE_FIB,
          ACRONYM_QUESTION_TYPE_SA,
          ACRONYM_QUESTION_TYPE_LA,
        ],
        [ACRONYM_QUESTION_TYPE_SA]: [],
        [ACRONYM_QUESTION_TYPE_LA]: [],
      };
      return notAllowedConversionQuestionTypesHash[oldQuestionType].includes(newQuestionType);
    },
    getAllBlocksForSelectedProductBoardStandardSubjectBlockIdMethod(payload) {
      this.getAllBlocksForSelectedProductBoardStandardSubjectBlockId(payload);
    },
    async convertIndividualToGroupQuestion(data) {
      if (!this.isViewOnly) {
        const questionData = _.cloneDeep(data);
        questionData.questionGroupId = 'to_generate';
        delete questionData._id;
        questionData.stimulus =
          '{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Enter Stimuli here"}]}]}';
        await this.instantiateContentEntryProjectData({
          type: this.lpType,
          data: [questionData],
        });
        this.deleteContentEntryQuestion({
          questionId: data._id,
          onSuccess: () => {
            this.questionForceSaveWithData({
              validateForm: false,
              showLoader: true,
              callback: this.saveForm,
            });
          },
        });
      }
      const questions = this.questionDataFromState;
      const newlyCreatedGroupQuestion = questions[questions.length - 1];
      const el = document.getElementById(newlyCreatedGroupQuestion._id);
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!this.isInitialDataLoaded && val.length > 0) {
          this.isInitialDataLoaded = true;
          this.SET_QUESTION_DATA(val);
        }
      },
    },
    questionData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('onQuestionDataChanged', val);
      },
    },
    currentStage: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.SET_QUESTION_CURRENT_STAGE(newVal);
        }
      },
    },
    currentView(val) {
      this.isDeatailedView = val == 'detailedView';
      this.currentView = val;
    },
    selectedQuestionpurposeItems() {
      this.updateFilterQuestionsBasedOnSelectedFilters();
    },
    groupedQuestions: {
      deep: true,
      immediate: true,
      handler() {
        this.updateFilterQuestionsBasedOnSelectedFilters();
      },
    },
    lessonPlans: {
      deep: true,
      immediate: true,
      handler() {
        this.onQuestionPurposeChange(this.questionpurposeItems.map((e) => e.value));
      },
    },
  },
  beforeDestroy() {
    this.RESET_QUESTION_DATA();
  },
  mounted() {
    this.RESTORE_LOCAL_STORAGE_DATA();
  },
  computed: {
    ...mapState({
      blockIdToBlockBIdMapping: (state) => state.questions.blockIdToBlockBIdMapping,
      showGroupQuestionDialog: (state) => state.questions.showGroupQuestionDialog,
      showDialog: (state) => state.questions.showQuestionDialog,
      showblockFilter: (state) => state.questions.showSourceBlockFilterDialog,
      virtualQuestionPurposes: (state) => state.questions.virtualQuestionPurposes,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      questionDataFromState: (state) => state.questions.questionData,
      // TODO: Product: PH and Board: XSEED One PH are blocked temprorary. As part of this ticket: OT-307
      boards: (state) => state.boards.filter((board) => !BLOCKED_BOARDS.includes(board.name)),
      standards: (state) => state.standards,
      products: (state) =>
        state.products.filter((product) => !BLOCKED_PRODUCTS.includes(product.name)),
      subjects: (state) => state.subjects,
      subjectShortName: (state) =>
        state.currentProject && state.currentProject.subject
          ? state.currentProject.subject.shortName
          : '',
      currentSubjectId: (state) =>
        state.currentProject && state.currentProject.subject ? state.currentProject.subject.id : '',
    }),
    groupedQuestionArray() {
      return Object.entries(this.filteredQuestions || []).map(([key, value]) => ({ key, value }));
    },
    isLanguageSubject() {
      return LANGUAGE_SUBJECT_SHORT_NAMES.includes(this.subjectShortName);
    },

    groupedQuestions() {
      let questionData = {};
      this.questionData.forEach((datum) => {
        if (!datum.questionGroupId) {
          questionData[datum._id] = _.cloneDeep(datum);
        } else {
          if (!questionData[datum.questionGroupId]) {
            questionData[datum.questionGroupId] = [];
          }
          questionData[datum.questionGroupId].push(_.cloneDeep(datum));
        }
      });
      return questionData;
    },
    questionpurposeItems() {
      let questionPurposesSet = new Set([]);
      for (const _singleQuestion of this.questionDataFromState) {
        questionPurposesSet.add(_singleQuestion.questionPurpose);
      }

      const questionPurposesArray = Array.from(questionPurposesSet);

      const questionpurposeItemsCopy = this.virtualQuestionPurposes.filter((_vqp) =>
        questionPurposesArray.includes(_vqp.value)
      );

      if (
        !this.selectedQuestionpurposeItems &&
        questionpurposeItemsCopy &&
        questionpurposeItemsCopy.length > 0
      ) {
        this.onQuestionPurposeChange(questionpurposeItemsCopy.map((e) => e.value));
      }

      return questionpurposeItemsCopy;
    },
    questionData() {
      return this.isViewOnly ? this.data : this.questionDataFromState;
    },
    isSchoolProgramQuestion() {
      return [
        LP_TYPES_HASH.VIRTUAL_QUESTIONS,
        LP_TYPES_HASH.LP_TYPE_MENTAL_MATH,
        LP_TYPES_HASH.LP_TYPE_REVISION,
      ].includes(this.lpType);
    },
    isVirtualQuestion() {
      return this.lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS;
    },
  },
  components: {
    InfoCard,
    CreateQuestionCard,
    QuestionDialog,
    QuestionGroupDialog,
    QuestionFilter,
    SingleQuestionView,
    SingleGroupQuestionView,
    Var,
    BlockFilterDialog,
    CreateQuestionCardWithGreenButtons,
    XSQuestionRenderer,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    saveForm: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    currentStage: {
      type: String,
      required: true,
    },
    isViewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldEmitDeleteAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShowActionButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
    forceUseAssetBasedOnQuestionProjectCurrentStageData: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMetaFields: {
      type: Boolean,
      default: true,
    },
    showCreateQuestionAsBottomBar: {
      type: Boolean,
      default: false,
    },
    hideInfoCard: {
      type: Boolean,
      default: false,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
    showMinimalDetails: {
      type: Boolean,
      default: false,
    },
    bufferSize: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style scoped>
.wrapper-container {
  background-color: rgba(248, 176, 6, 0.1);
  border-radius: 5px;
}
</style>
