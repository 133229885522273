export default {
  COVER_IMAGE_ASSET_TYPE: 'coverImageUrl',
  CONTENT_BOOK_ASSET_TYPE: 'contentBookImageUrl',
  STUDENT_WORK_BOOK_ASSET_TYPE: 'studentWorkbookImageUrl',
  STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE: 'studentWorkbookAnswerImageUrl',
  BLACK_BOARD_ASSET_TYPE: 'blackboardImageUrl',
  HOMEWORK_QUESTION_ASSET_TYPE: 'homeworkQuestionImageUrl',
  HOMEWORK_ANSWER_ASSET_TYPE: 'homeworkAnswerImageUrl',
  ACTION_VIDEO_ASSET_TYPE: 'actionVideoUrl',
  ACTIVITY_EXPLANATION_ASSET_TYPE: 'activityExplanationImageUrl',
  GENERALIZED_EXPLANATION_ASSET_TYPE: 'generalizedExplanationImageUrl',
  HIGHER_ORDER_THINKING_KEY_QUESTION_ASSET_TYPE: 'higherOrderThinkingKeyQuestionAssets',
  HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE: 'higherOrderThinkingExplanationImageUrl',
  RESOURCES_ASSET_TYPE: 'resources',
  IMPORT_ASSET_TYPE: 'asset',
  STEP_ASSET: 'stepAsset',
  EP_GENERALIZED_EXPLANATION_ASSET_TYPE: 'epGeneralizedExplanationImageUrl',
  EP_HOMEWORK_QUESTION_ASSET_TYPE: 'epHomeworkQuestionImageUrl',
  EP_HOMEWORK_QUESTION_ANSWER_ASSET_TYPE: 'epHomeworkQuestionAnswerImageUrl',
  EP_CLASSWORK_QUESTION_ASSET_TYPE: 'epClassworkQuestionImageUrl',
  EP_CLASSWORK_QUESTION_ANSWER_ASSET_TYPE: 'epClassworkQuestionAnswerImageUrl',
  INFORMATION_AND_ASSESSMENT_QUESTION_ASSET_TYPE: 'informationAndAssessmentQuestionImageUrl',
  INFORMATION_AND_ASSESSMENT_ANSWER_ASSET_TYPE: 'informationAndAssessmentAnswerImageUrl',
  INFORMATION_AND_ASSESSMENT_SECONDARY_ASSET_TYPE: 'informationAndAssessmentSecondaryImageUrl',
  DISCUSSION_APPLICATION_IMAGE: 'discussionApplicationImageUrl',
  DISCUSSION_ANALYSIS_CONTENT_BOOK_PDF: 'discussionAnalysisContentBookPDFUrl',
  DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_PRIMARY: 'discussionAnalysisBoardworkImagePrimaryUrl',
  DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_SECONDARY: 'discussionAnalysisBoardworkImageSecondaryUrl',
  DISCUSSION_ANALYSIS_QUESTION_IMAGE_ASSET: 'discussionAnalysisQuestionPDFUrl',
  DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_ANSWER_IMAGE_ASSET:
    'discussionAnalysisInstructionQuestionAnswerAssset',
  DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_IMAGE_ASSET:
    'discussionAnalysisInstructionQuestionAssset',
  DISCUSSION_ANALYSIS_QUESTION_EXPLANATION_IMAGE_ASSET: 'discussionAnalysisExplanationPDFUrl',
  PRACTICE_HOMEWORK_PROJECT_WORK_ASSET: 'practiceHomeworkProjectWorkAsset',
  PRACTICE_HOMEWORK_MY_LEARNING_CHART_ASSET: 'practiceHomeworkMyLearningChartAsset',
  PRACTICE_HOMEWORK_MY_LEARNING_CHART_ANSWER_ASSET: 'practiceHomeworkMyLearningChartAnswerAsset',
};
