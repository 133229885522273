var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(!_vm.isViewOnly && !_vm.hideInfoCard)?_c('v-col',{attrs:{"cols":"12"}},[_c('InfoCard',{attrs:{"lpType":_vm.lpType}})],1):_vm._e(),(_vm.isVirtualQuestion)?_c('v-col',[_c('QuestionFilter',{attrs:{"questionpurposeItems":_vm.questionpurposeItems,"selectedQuestionpurposeItems":_vm.selectedQuestionpurposeItems,"lessonPlanItems":_vm.lessonPlansFilterData,"selectedLessonPlanItems":_vm.selectedLessonPlanItems,"difficultyLevelItems":_vm.difficultyLevelsFilterData,"selectedDifficultyLevelItems":_vm.selectedDifficultyLevelItems,"questionPropertiesToIncludeInDownload":_vm.questionPropertiesToIncludeInDownload,"downloadQuestions":_vm.downloadQuestionsHandler,"isReadOnly":!_vm.options.isAssetUploadRequired},on:{"onQuestionPurposeChange":_vm.onQuestionPurposeChange,"onLessonPlanFilterChange":_vm.onLessonPlanFilterChange,"onDifficultyLevelFilterChange":_vm.onDifficultyLevelFilterChange,"onViewChange":function (value) {
            _vm.currentView = value;
          },"onChangeFileType":function (value) {
            _vm.fileType = value;
          }}})],1):_vm._e(),(!_vm.isViewOnly && !_vm.showCreateQuestionAsBottomBar)?_c('v-col',{staticClass:"mt-6 mb-6",attrs:{"cols":"12"}},[_c('CreateQuestionCard',{attrs:{"data":_vm.questionData,"isReadOnly":_vm.options.isReadOnly,"lpType":_vm.lpType}})],1):_vm._e(),(_vm.isDeatailedView)?_c('v-col',{attrs:{"cols":"12"}},[_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.groupedQuestionArray,"buffer":_vm.bufferSize,"minItemSize":300,"keyField":"key","simpleArray":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
          var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[],"data-index":index}},[_c('Var',{attrs:{"datum":item['value']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var datum = ref.datum;
return [_c('Var',{attrs:{"updatedOptions":_vm.getOptionsBasedOnQuestionData(datum)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var updatedOptions = ref.updatedOptions;
return [_c('XSQuestionRenderer',{attrs:{"questionIndex":index,"datum":datum,"saveForm":_vm.saveForm,"options":updatedOptions,"toggleComments":_vm.toggleComments,"isViewOnly":_vm.isViewOnly,"canShowActionButtons":_vm.canShowActionButtons,"isSchoolProgramQuestion":_vm.isSchoolProgramQuestion,"showMinimalDetails":_vm.showMinimalDetails,"shouldEmitDeleteAction":_vm.shouldEmitDeleteAction,"questionMetaInfo":_vm.questionMetaInfo || {},"boards":_vm.boards,"blockIdToBlockBIdMapping":_vm.blockIdToBlockBIdMapping,"questionDataFromState":_vm.questionDataFromState,"getAllBlocksForSelectedProductBoardStandardSubjectBlockId":_vm.getAllBlocksForSelectedProductBoardStandardSubjectBlockIdMethod,"convertIndividualToGroupQuestion":_vm.convertIndividualToGroupQuestion,"openQuestionDialog":_vm.openQuestionDialog,"deleteQuestion":_vm.deleteQuestion,"openGroupQuestionDialog":_vm.openGroupQuestionDialog,"deleteGroupQuestion":_vm.deleteGroupQuestion,"lpType":_vm.lpType,"questionPurposeDisplayText":_vm.getQuestionPurposeDisplayText,"getAnswerCriteria":_vm.getAnswerCriteria},on:{"question-preview-cliked":function (e) { return _vm.$emit('question-preview-cliked', e); },"remove-question-clicked":function (e) { return _vm.$emit('remove-question-clicked', e); }}})]}}],null,true)})]}}],null,true)})],1)]}}],null,false,3796546939)})],1):_c('v-col',{attrs:{"cols":"12"}},_vm._l((Object.keys(_vm.filteredQuestions)),function(key,index){return _c('div',{key:index},[(Array.isArray(_vm.filteredQuestions[key]))?_c('div',{staticClass:"list-view-question-wrapper"},[_c('SingleGroupQuestionView',{attrs:{"isSelected":_vm.filteredQuestions[key].some(function (e) { return e.isSelected; }),"index":index + 1,"questionMetaInfo":_vm.questionMetaInfo,"lessonPlans":_vm.lessonPlans,"data":_vm.filteredQuestions[key],"canSelectItem":true},on:{"onSelectQuestion":function (isSelected, data) {
                _vm.toggleGroupQuestionSelection(isSelected, data);
              }}})],1):_c('div',{staticClass:"list-view-question-wrapper"},[_c('SingleQuestionView',{attrs:{"isSelected":_vm.filteredQuestions[key].isSelected,"index":index + 1,"questionMetaInfo":_vm.questionMetaInfo,"lessonPlans":_vm.lessonPlans,"data":_vm.filteredQuestions[key],"canSelectItem":true},on:{"onSelectQuestion":function (isSelected, data) {
                _vm.toggleQuestionSelection(isSelected, data);
              }}})],1)])}),0),(_vm.showCreateQuestionAsBottomBar)?_c('v-col',{staticClass:"mt-6 mb-6",attrs:{"cols":"12"}},[_c('CreateQuestionCardWithGreenButtons',{attrs:{"data":_vm.questionData,"isReadOnly":_vm.options.isReadOnly,"lpType":_vm.lpType,"modifyChangeQuestionBaseData":_vm.modifyChangeQuestionBaseData,"selectedEntityType":_vm.options.selectedEntityType}})],1):_vm._e()],1),(_vm.showDialog)?_c('QuestionDialog',{attrs:{"saveForm":_vm.saveForm,"options":_vm.options,"canAppproveAsset":_vm.canAppproveAsset,"lpType":_vm.lpType,"lessonPlans":_vm.lessonPlans,"isLanguageSubject":_vm.isLanguageSubject,"isSchoolProgramQuestion":_vm.isSchoolProgramQuestion,"modifyQuestionDataHash":_vm.modifyQuestionDataHash,"checkIfOptionsWillNotBeRetained":_vm.checkIfOptionsWillNotBeRetained,"showMetaFields":_vm.showMetaFields,"modifyChangeQuestionBaseData":_vm.modifyChangeQuestionBaseData,"allowedQuestionTypes":_vm.entityAllowedQuestionTypes[_vm.options.selectedEntityType]}}):_vm._e(),(_vm.showGroupQuestionDialog)?_c('QuestionGroupDialog',{attrs:{"saveForm":_vm.saveForm,"options":_vm.options,"canAppproveAsset":_vm.canAppproveAsset,"lpType":_vm.lpType,"lessonPlans":_vm.lessonPlans,"isLanguageSubject":_vm.isLanguageSubject,"isSchoolProgramQuestion":_vm.isSchoolProgramQuestion,"modifyQuestionDataHash":_vm.modifyQuestionDataHash,"checkIfOptionsWillNotBeRetained":_vm.checkIfOptionsWillNotBeRetained,"showMetaFields":_vm.showMetaFields,"modifyChangeQuestionBaseData":_vm.modifyChangeQuestionBaseData}}):_vm._e(),_c('BlockFilterDialog',{attrs:{"filterOptions":{ boards: _vm.boards, products: _vm.products, standards: _vm.standards, subjects: _vm.subjects },"showblockFilter":_vm.showblockFilter,"sourceBlockLessons":_vm.lessonPlans,"saveForm":_vm.saveForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }