<template>
  <v-form ref="analyseSectionForm" class="section-title-cnr">
    <div v-if="schema.showUniversalDiscussion">
      <!-- DISCUSSION BLOCK -->
      <section v-if="schema.showDiscussionKeyQuestionAndGuide">
        <BlockTitleWithSubtitle
          :title="schema.showPart1Text ? 'Part 1' : null"
          :subTitle="schema.label.activityDiscussion"
        />
        <div class="mgn-Tm mgn-Ls">
          <div class="mgn-Bs">{{ schema.prefix }}</div>
          <div class="mgn-Bs" v-if="schema.showKeyQuestion">
            {{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}
          </div>
        </div>
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiscussionKeyQuestionChanged('discussion')"
          fieldIdentifier="analysis.discussion.key_question"
          :placeholder="AppTexts.KEY_QUESTION"
          :showValidationError="true"
          v-model="lessonPlanData.analysis.discussion.key_question"
          :readonly="!isNotReadOnly"
          :counter="schema.discussionKeyQuestionMaxLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />

        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiscussionKeyQuestionGuideChanged('discussion')"
          fieldIdentifier="analysis.discussion.key_question_guide"
          :placeholder="AppTexts.KEY_QUESTION_GUIDE"
          :showValidationError="schema.isKeyQuestionGuideMandatory"
          v-model="lessonPlanData.analysis.discussion.key_question_guide"
          :readonly="!isNotReadOnly"
          :counter="schema.discussionKeyQuestionGuideMaxLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </section>
      <section v-if="schema.showDiscussionExplanation">
        <BlockTitleWithSubtitle :subTitle="schema.label.activityExplanation" />
        <Step
          :items="lessonPlanData.analysis.discussion.instructions"
          :addNewSubStepHandler="
            (stepIndex) => {
              addAnalysisSubStep({ stepIndex, key: 'discussion' });
            }
          "
          :canShowStepNumber="true"
          :canShowInstructionBreakCheckbox="true"
          :hasSubStep="true"
          :maxNoOfSteps="schema.maxNoOfDiscussionSteps"
          :maxNoOfSubStepsInStep="schema.maxNoOfSubStepsDiscussionStep"
          :maxSumOfStepsAndSubSteps="schema.maxSumOfStepsAndSubDiscussionSteps"
          :supportAssets="true"
          :descriptionPlaceholder="AppTexts.EXPLANATION"
          :canShowAddNewStepButton="true"
          :allowDeleteStep="true"
          :deleteStepHandler="deleteAnalysisDiscussionStep"
          :allowDeleteSubStep="true"
          :deleteSubStepHandler="deleteAnalysisDiscussionSubStep"
          :canShowStepBullet="true"
          :canShowDiffIcon="canShowDiffIcon"
          stepAssetIdentifier="analysis.discussion.instructions"
          stepDiffIdentifier="analysis.discussion.instructions[<stepIndex>].description"
          subStepDiffIdentifier="analysis.discussion.instructions[<stepIndex>].children[<subStepIndex>].description"
          assetBriefDiffIdentifier="analysis.discussion.instructions[<stepIndex>].assets[<assetIndex>].briefAssetId"
          assetImageDiffIdentifierPrefix="analysis.discussion.instructions[<stepIndex>].assets[<assetIndex>]"
          :addNewStepHandler="addAnalysiDiscussionStep"
          :isStepDescriptionChanged="isADStepDescriptionChanged"
          :isNotReadOnly="isNotReadOnly"
          :getPreviewUrl="getAnalysisAssetPreviewUrl"
          :showImageBrief="true"
          class="analysis-explanation-step"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetUploadRequired="isAssetUploadRequired"
          instructionTeacherTipDiffIdentifier="analysis.discussion.instructions[<stepIndex>].teacher_tip"
          :canShowInstructionTeacherTip="true"
          :canShowLastInstructionTeacherTip="false"
          :toggleTeacherTipFieldHandler="toggleAnalysisDiscussionInstructionTeacherTipField"
          :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
          :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
          :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
          :signedUrls="analysisSignedUrls.discussion.instructions"
          :uploadingStatus="uploadingStatus"
          :assetTypeMapping="{
            question: AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_IMAGE_ASSET,
            question_answer: AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_ANSWER_IMAGE_ASSET,
          }"
          :isStepHomeworkDiffChanged="isStepHomeworkDiffChanged"
        />
        <v-divider class="mgn-Ts mgn-Bs" />
      </section>
      <div class="mgn-Ts">
        <XSDisplayCard :title="AppTexts.TEACHER_TIP">
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDiscussionTeacherTipGuideChanged('discussion')"
            fieldIdentifier="analysis.discussion.teacher_tip"
            :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
            v-model="lessonPlanData.analysis.discussion.teacher_tip"
            :counter="schema.discussionTeacherTipMaxLength"
            :readonly="!isNotReadOnly"
            :includeOnlyToolBarActions="BASIC_TOOLS"
            class="mgn-Bm"
          />
        </XSDisplayCard>
      </div>
    </div>
    <div v-else>
      <!-- ACTIVITY_DISCUSSION BLOCK -->
      <BlockTitleWithSubtitle title="Part 1" :subTitle="AppTexts.ACTIVITY_DISCUSSION" />
      <XSRightSwitch
        :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_DISCUSSION_STANDARD_LP"
        :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
        subPath="analysis.activity_discussion"
        :initialActive="
          isSlideLayoutEnabled('analysis.activity_discussion.instructions.0', lessonPlanData)
        "
        :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
      >
        <template #false-slot>
          <div class="mgn-Tm mgn-Ls">
            <div class="mgn-Bs">
              {{ analysisBockStaticTexts.activity_discussion.paragraph_text }}
            </div>
            <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
          </div>
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDiscussionKeyQuestionChanged('activity_discussion')"
            fieldIdentifier="analysis.activity_discussion.key_question"
            :placeholder="AppTexts.KEY_QUESTION"
            :showValidationError="true"
            v-model="lessonPlanData.analysis.activity_discussion.key_question"
            :readonly="!isNotReadOnly"
            :counter="schema.discussionKeyQuestionMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
          <br />
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDiscussionKeyQuestionGuideChanged('activity_discussion')"
            fieldIdentifier="analysis.activity_discussion.key_question_guide"
            :placeholder="AppTexts.KEY_QUESTION_GUIDE"
            :showValidationError="true"
            v-model="lessonPlanData.analysis.activity_discussion.key_question_guide"
            :readonly="!isNotReadOnly"
            :counter="schema.discussionKeyQuestionGuideMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </template>
        <template #true-slot>
          <MultiSlideLayout
            :isNotReadOnly="isNotReadOnly"
            subPath="analysis.activity_discussion.instructions"
            assetSubPath="analysis.activityDiscussion.instructions"
            :lessonPlanData="lessonPlanData"
            :isAssetUploadRequired="isAssetUploadRequired"
            :canAppproveAsset="canAppproveAsset"
            :signedUrls="signedUrls"
            :canShowDiffIcon="canShowDiffIcon"
            :showImageBrief="showImageBrief"
            :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
          />
        </template>
      </XSRightSwitch>

      <BlockTitle class="analysis-explanation-title" :title="AppTexts.ACTIVITY_EXPLANATION" />

      <XSRightSwitch
        :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_EXPLANATION_STANDARD_LP"
        :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_LAYOUT"
        subPath="analysis.activity_discussion.explanations"
        :initialActive="
          isSlideLayoutEnabled('analysis.activity_discussion.explanations.0', lessonPlanData)
        "
        :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
      >
        <template #false-slot>
          <Step
            :items="lessonPlanData.analysis.activity_discussion.instructions"
            :addNewStepHandler="addAnalysisActivityDiscussionStep"
            :descriptionPlaceholder="AppTexts.EXPLANATION"
            :canShowAddNewStepButton="true"
            :hasSubStep="false"
            :allowDeleteStep="true"
            :deleteStepHandler="deleteAnalysisActivityDiscussionStep"
            :maxNoOfSteps="4"
            :canShowStepBullet="true"
            :canShowDiffIcon="canShowDiffIcon"
            stepDiffIdentifier="analysis.activity_discussion.instructions[<stepIndex>].description"
            :isStepDescriptionChanged="isADStepDescriptionChanged"
            class="analysis-explanation-step"
            :isNotReadOnly="isNotReadOnly"
            :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
            :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
            :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
          />
          <v-divider class="mgn-Ts mgn-Bs" />
          <MultipleAssetUploader
            :items="lessonPlanData.analysis.activity_discussion.explanations"
            :addAssetAction="addAnaysisExplanationAssets"
            :deleteAssetAction="deleteAnaysisExplanationAssets"
            :canShowDiffIcon="canShowDiffIcon"
            :itemDiff="getExaplanationDiff('activity_discussion')"
            diffIdentifierPrefix="analysis.activity_discussion.explanations"
            :assetType="AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE"
            withAdd
            :uploadAssetAction="importAnalysisExplanationAssetAction"
            :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
            :placeholder="AppTexts.TITLE"
            :isRequired="false"
            :label="AppTexts.SELECT_FILE"
            :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
            :showImageBrief="showImageBrief"
            :isAssetUploadRequired="isAssetUploadRequired"
            :acceptableFileType="acceptableFileType"
            :assetsSignedUrls="analysisSignedUrls.activityDiscussion.explanations"
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :canFinalizeAsset="true"
            :canApproveAsset="canAppproveAsset"
          />
          <div class="mgn-Ts">
            <XSDisplayCard :title="AppTexts.TEACHER_TIP">
              <RichTextEditor
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="isDiscussionTeacherTipGuideChanged('activity_discussion')"
                fieldIdentifier="analysis.activity_discussion.teacher_tip"
                :hintText="AppTexts.TEACHER_TIP_DESCRIPTION"
                v-model="lessonPlanData.analysis.activity_discussion.teacher_tip"
                :counter="schema.discussionTeacherTipMaxLength"
                :readonly="!isNotReadOnly"
                :includeOnlyToolBarActions="BASIC_TOOLS"
                class="mgn-Bm"
              />
            </XSDisplayCard>
          </div>
        </template>
        <template #true-slot>
          <MultiSlideLayout
            :isNotReadOnly="isNotReadOnly"
            subPath="analysis.activity_discussion.explanations"
            assetSubPath="analysis.activityDiscussion.explanations"
            :lessonPlanData="lessonPlanData"
            :isAssetUploadRequired="isAssetUploadRequired"
            :canAppproveAsset="canAppproveAsset"
            :signedUrls="signedUrls"
            :canShowDiffIcon="canShowDiffIcon"
            :showImageBrief="showImageBrief"
            :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
          />
        </template>
      </XSRightSwitch>

      <!-- GENERALIZED_DISCUSSION BLOCK -->
      <br />
      <div>
        <BlockTitleWithSubtitle title="Part 2" :subTitle="AppTexts.GENERALIZED_DISCUSSION" />
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_DISCUSSION_STANDARD_LP"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
          subPath="analysis.generalized_discussion"
          :initialActive="
            isSlideLayoutEnabled('analysis.generalized_discussion.instructions.0', lessonPlanData)
          "
          :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
        >
          <template #false-slot>
            <div class="mgn-Tm mgn-Ls">
              <div class="mgn-Bs">
                {{ analysisBockStaticTexts.generalized_discussion.paragraph_text }}
              </div>
              <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
            </div>
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiscussionKeyQuestionChanged('generalized_discussion')"
              fieldIdentifier="analysis.generalized_discussion.key_question"
              :placeholder="AppTexts.KEY_QUESTION"
              :showValidationError="true"
              v-model="lessonPlanData.analysis.generalized_discussion.key_question"
              :readonly="!isNotReadOnly"
              :counter="schema.discussionKeyQuestionMaxLength"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
            <br />
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiscussionKeyQuestionGuideChanged('generalized_discussion')"
              fieldIdentifier="analysis.generalized_discussion.key_question_guide"
              :placeholder="AppTexts.KEY_QUESTION_GUIDE"
              :showValidationError="true"
              v-model="lessonPlanData.analysis.generalized_discussion.key_question_guide"
              :readonly="!isNotReadOnly"
              :counter="schema.discussionKeyQuestionGuideMaxLength"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </template>
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="isNotReadOnly"
              subPath="analysis.generalized_discussion.instructions"
              assetSubPath="analysis.generalizedDiscussion.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
            />
          </template>
        </XSRightSwitch>
      </div>
      <div>
        <BlockTitle :title="AppTexts.GENERALIZED_EXPLANATION" />
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_EXPLANATION_STANDARD_LP"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_LAYOUT"
          subPath="analysis.generalized_discussion.explanations"
          :initialActive="
            isSlideLayoutEnabled('analysis.generalized_discussion.explanations.0', lessonPlanData)
          "
          :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
        >
          <template #false-slot>
            <Step
              :items="lessonPlanData.analysis.generalized_discussion.instructions"
              :canShowAddNewStepButton="true"
              :addNewStepHandler="addAnalysisGeneralizedDiscussionStep"
              :allowDeleteStep="true"
              :deleteStepHandler="deleteAnalysisGeneralizedDiscussionStep"
              :descriptionPlaceholder="AppTexts.EXPLANATION"
              :maxNoOfSteps="4"
              :canShowStepBullet="true"
              :canShowDiffIcon="canShowDiffIcon"
              stepDiffIdentifier="analysis.generalized_discussion.instructions[<stepIndex>].description"
              :isStepDescriptionChanged="isGDStepDescriptionChanged"
              class="analysis-explanation-step"
              :isNotReadOnly="isNotReadOnly"
              :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
              :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
              :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
            />
            <v-divider class="mgn-Ts mgn-Bs" />
            <MultipleAssetUploader
              :items="lessonPlanData.analysis.generalized_discussion.explanations"
              :addAssetAction="addAnaysisExplanationAssets"
              :deleteAssetAction="deleteAnaysisExplanationAssets"
              :canShowDiffIcon="canShowDiffIcon"
              :itemDiff="getExaplanationDiff('generalized_discussion')"
              diffIdentifierPrefix="analysis.generalized_discussion.explanations"
              :assetType="AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE"
              withAdd
              :uploadAssetAction="importAnalysisExplanationAssetAction"
              :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
              :placeholder="AppTexts.TITLE"
              :isRequired="false"
              :label="AppTexts.SELECT_FILE"
              :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :acceptableFileType="acceptableFileType"
              :assetsSignedUrls="analysisSignedUrls.generalizedDiscussion.explanations"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :canFinalizeAsset="true"
              :canApproveAsset="canAppproveAsset"
            />
            <div class="mgn-Ts">
              <XSDisplayCard :title="AppTexts.TEACHER_TIP">
                <RichTextEditor
                  :canShowDiffIcon="canShowDiffIcon"
                  :isDiffChanged="isDiscussionTeacherTipGuideChanged('generalized_discussion')"
                  fieldIdentifier="analysis.generalized_discussion.teacher_tip"
                  :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
                  v-model="lessonPlanData.analysis.generalized_discussion.teacher_tip"
                  :counter="schema.discussionTeacherTipMaxLength"
                  :readonly="!isNotReadOnly"
                  :includeOnlyToolBarActions="BASIC_TOOLS"
                  class="mgn-Bm"
                />
              </XSDisplayCard>
            </div>
          </template>
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="isNotReadOnly"
              subPath="analysis.generalized_discussion.explanations"
              assetSubPath="analysis.generalizedDiscussion.explanations"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
            />
          </template>
        </XSRightSwitch>
      </div>

      <!-- HIGHER_ORDER_THINKING BLOCK -->
      <br />
      <BlockTitleWithSubtitle title="Part 3" :subTitle="AppTexts.HIGHER_ORDER_THINKING" />
      <div>
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_DISCUSSION_STANDARD_LP"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
          subPath="analysis.higher_order_thinking"
          :initialActive="
            isSlideLayoutEnabled('analysis.higher_order_thinking.instructions.0', lessonPlanData)
          "
          :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
        >
          <template #false-slot>
            <div class="mgn-Tm mgn-Ls">
              <div class="mgn-Bs">
                {{ analysisBockStaticTexts.higher_order_thinking.paragraph_text }}
              </div>
              <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
            </div>

            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiscussionKeyQuestionGuideChanged('higher_order_thinking')"
              fieldIdentifier="analysis.higher_order_thinking.key_question"
              :placeholder="AppTexts.KEY_QUESTION"
              :showValidationError="true"
              v-model="lessonPlanData.analysis.higher_order_thinking.key_question"
              :readonly="!isNotReadOnly"
              :counter="schema.discussionKeyQuestionMaxLength"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
            <br />
            <MultipleAssetUploader
              :items="
                (lessonPlanData.analysis.higher_order_thinking || {}).key_question_assets || []
              "
              :addAssetAction="addAnaysisExplanationAssets"
              :deleteAssetAction="deleteAnaysisExplanationAssets"
              :canShowDiffIcon="canShowDiffIcon"
              :itemDiff="getExaplanationDiff('higher_order_thinking')"
              diffIdentifierPrefix="analysis.higher_order_thinking.key_question_assets"
              :assetType="AssetTypes.HIGHER_ORDER_THINKING_KEY_QUESTION_ASSET_TYPE"
              withAdd
              :uploadAssetAction="importAnalysisExplanationAssetAction"
              :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
              :placeholder="AppTexts.TITLE"
              :isRequired="false"
              :label="AppTexts.SELECT_FILE"
              :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :acceptableFileType="acceptableFileType"
              :assetsSignedUrls="
                (analysisSignedUrls.higherOrderThinking || {}).key_question_assets || []
              "
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :canFinalizeAsset="true"
              :canApproveAsset="canAppproveAsset"
            />
            <br />
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiscussionKeyQuestionChanged('higher_order_thinking')"
              fieldIdentifier="analysis.higher_order_thinking.key_question_guide"
              :placeholder="AppTexts.KEY_QUESTION_GUIDE"
              :showValidationError="true"
              v-model="lessonPlanData.analysis.higher_order_thinking.key_question_guide"
              :readonly="!isNotReadOnly"
              :counter="schema.discussionKeyQuestionGuideMaxLength"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </template>
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="isNotReadOnly"
              subPath="analysis.higher_order_thinking.instructions"
              assetSubPath="analysis.higherOrderThinking.instructions"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
            />
          </template>
        </XSRightSwitch>
      </div>
      <div>
        <BlockTitle :title="AppTexts.HIGHER_ORDER_THINKING_EXPLANATION" />
        <XSRightSwitch
          :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_EXPLANATION_STANDARD_LP"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_LAYOUT"
          subPath="analysis.higher_order_thinking.explanations"
          :initialActive="
            isSlideLayoutEnabled('analysis.higher_order_thinking.explanations.0', lessonPlanData)
          "
          :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
        >
          <template #false-slot>
            <Step
              :items="lessonPlanData.analysis.higher_order_thinking.instructions"
              :canShowAddNewStepButton="true"
              :addNewStepHandler="addAnalysisHigherOrderStep"
              :allowDeleteStep="true"
              :deleteStepHandler="deleteAnalysisHigherOrderStep"
              :descriptionPlaceholder="AppTexts.EXPLANATION"
              :maxNoOfSteps="4"
              :canShowStepBullet="true"
              :canShowDiffIcon="canShowDiffIcon"
              stepDiffIdentifier="analysis.higher_order_thinking.instructions[<stepIndex>].description"
              :isStepDescriptionChanged="isHOTSStepDescriptionChanged"
              class="analysis-explanation-step"
              :isNotReadOnly="isNotReadOnly"
              :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
              :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
              :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
            />
            <v-divider class="mgn-Ts mgn-Bs" />
            <MultipleAssetUploader
              :items="lessonPlanData.analysis.higher_order_thinking.explanations"
              :addAssetAction="addAnaysisExplanationAssets"
              :deleteAssetAction="deleteAnaysisExplanationAssets"
              :canShowDiffIcon="canShowDiffIcon"
              :itemDiff="getExaplanationDiff('higher_order_thinking')"
              diffIdentifierPrefix="analysis.higher_order_thinking.explanations"
              :assetType="AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE"
              withAdd
              :uploadAssetAction="importAnalysisExplanationAssetAction"
              :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
              :placeholder="AppTexts.TITLE"
              :isRequired="false"
              :label="AppTexts.SELECT_FILE"
              :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :acceptableFileType="acceptableFileType"
              :assetsSignedUrls="analysisSignedUrls.higherOrderThinking.explanations"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :canFinalizeAsset="true"
              :canApproveAsset="canAppproveAsset"
            />
            <div class="mgn-Ts mgn-Bs">
              <XSDisplayCard :title="AppTexts.TEACHER_TIP">
                <RichTextEditor
                  :canShowDiffIcon="canShowDiffIcon"
                  :isDiffChanged="isDiscussionTeacherTipGuideChanged('higher_order_thinking')"
                  fieldIdentifier="analysis.higher_order_thinking.teacher_tip"
                  :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
                  v-model="lessonPlanData.analysis.higher_order_thinking.teacher_tip"
                  :counter="schema.discussionTeacherTipMaxLength"
                  :readonly="!isNotReadOnly"
                  :includeOnlyToolBarActions="BASIC_TOOLS"
                  class="mgn-Bm"
                />
              </XSDisplayCard>
            </div>
          </template>
          <template #true-slot>
            <MultiSlideLayout
              :isNotReadOnly="isNotReadOnly"
              subPath="analysis.higher_order_thinking.explanations"
              assetSubPath="analysis.higherOrderThinking.explanations"
              :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
            />
          </template>
        </XSRightSwitch>
      </div>
    </div>
    <div style="padding: 5px 0px;" v-if="schema.showReadXSEEDContentBookCheckbox">
      <v-checkbox
        color="success"
        style="width: 250px; height: 40px;"
        :class="lessonPlanData.analysis.readXSEEDContentBook ? 'selected-box' : 'not-selected-box'"
        :background-color="lessonPlanData.analysis.readXSEEDContentBook ? '#37B074' : '#D9D9D9'"
        v-model="lessonPlanData.analysis.readXSEEDContentBook"
      >
        <template v-slot:label>
          <div
            :style="lessonPlanData.analysis.readXSEEDContentBook ? 'color: white' : 'color: black'"
            class="pt-2 pb-2 mb-0"
          >
            Read XSEED Content Book
          </div>
        </template>
      </v-checkbox>
    </div>

    <!-- Coding Connection -->
    <v-section v-if="schema.showCodingConnection">
      <BlockTitleWithSubtitle title="Part 4" :subTitle="AppTexts.CODING_CONNECTION" />
      <XSRightSwitch
        :primaryDs="COMPONENT_TO_DS_MAP.INSTRUCTION"
        :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
        subPath="analysis.coding_connection"
        :initialActive="
          isSlideLayoutEnabled('analysis.coding_connection.instructions.0', lessonPlanData)
        "
        :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
      >
        <template #false-slot>
          <Step
            :items="lessonPlanData.analysis.coding_connection.instructions"
            :canShowStepNumber="true"
            :canShowInstructionBreakCheckbox="true"
            :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
            :canShowAddNewStepButton="true"
            :addNewStepHandler="addInstructionStepForPath"
            :hasSubStep="true"
            :allowDeleteStep="true"
            :deleteStepHandler="deleteInstructionStepForPath"
            :allowDeleteSubStep="true"
            :deleteSubStepHandler="deleteInstructionSubStepForPath"
            :descriptionPlaceholder="AppTexts.STEP_DESCRIPTION"
            :maxNoOfSteps="schema.maxNoOfSteps"
            :maxNoOfSubStepsInStep="schema.maxNoOfSubStepsInStep"
            :maxSumOfStepsAndSubSteps="schema.maxSumOfStepsAndSubSteps"
            :supportAssets="true"
            :showImageBrief="showImageBrief"
            :isAssetUploadRequired="isAssetUploadRequired"
            :getPreviewUrl="getCodingConnectionInstructionAssetPreviewUrl"
            stepAssetIdentifier="analysis.coding_connection.instructions"
            :actionSignedUrls="actionSignedUrls"
            :canShowDiffIcon="canShowDiffIcon"
            stepDiffIdentifier="analysis.coding_connection.instructions[<stepIndex>].description"
            :isStepDescriptionChanged="isStepDescriptionChanged"
            subStepDiffIdentifier="analysis.coding_connection.instructions[<stepIndex>].children[<subStepIndex>].description"
            :isSubStepDescriptionChanged="isSubStepDescriptionChanged"
            assetBriefDiffIdentifier="analysis.coding_connection.instructions[<stepIndex>].assets[<assetIndex>].brief"
            :isAssetBriefChanged="isAssetBriefChanged"
            assetImageDiffIdentifierPrefix="analysis.coding_connection.instructions[<stepIndex>].assets[<assetIndex>]"
            :isAssetImageChanged="isAssetImageChanged"
            :showDividerBetweenSteps="true"
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :addNewSubStepHandler="addInstructionSubStepForPath"
            :canFinalizeAsset="true"
            :canAppproveAsset="canAppproveAsset"
            instructionTeacherTipDiffIdentifier="analysis.coding_connection.instructions[<stepIndex>].teacher_tip"
            :canShowInstructionTeacherTip="true"
            :canShowLastInstructionTeacherTip="false"
            :toggleTeacherTipFieldHandler="toggleInstructionTeacherTipBasedOnPath"
            :stepDescriptionMaxLength="schema.stepDescriptionMaxLength"
            :subStepDescriptionMaxLength="schema.subStepDescriptionMaxLength"
            :stepTeacherTipMaxLength="schema.stepTeacherTipMaxLength"
            :supportAudioWord="schema.showInstructionAudioWord"
            :maxAudioWordLength="schema.maxAudioWordLength"
            :maxAudioWordNameLength="schema.maxAudioWordNameLength"
            :maxAudioWordDescriptionLength="schema.maxAudioWordDescriptionLength"
            diffIdentifierPrefix="analysis.coding_connection.instructions[<stepIndex>]"
            subPath="analysis.coding_connection.instructions"
          />
        </template>
        <template #true-slot>
          <MultiSlideLayout
            :isNotReadOnly="isNotReadOnly"
            subPath="analysis.coding_connection.instructions"
            :lessonPlanData="lessonPlanData"
            :isAssetUploadRequired="isAssetUploadRequired"
            :canAppproveAsset="canAppproveAsset"
            :signedUrls="signedUrls"
            :canShowDiffIcon="canShowDiffIcon"
            :showImageBrief="showImageBrief"
            :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
          />
        </template>
      </XSRightSwitch>
    </v-section>

    <!-- <div class="pad-Ts mgn-Bs">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isActionTeacherTipChanged()"
          fieldIdentifier="action.teacher_tip"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.action.teacher_tip"
          :isRequired="false"
          :value="lessonPlanData.action.teacher_tip"
          :counter="schema.teacherTipMaxLength"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div>
    <div style="padding: 5px 0px;">
      <RTEWithMultipleAssetUpload
        v-if="lessonPlanData.action.homework"
        :data="lessonPlanData.action.homework"
        :isReadOnly="!isNotReadOnly"
        :isAssetUploadRequired="isAssetUploadRequired"
        :canShowDiffIcon="canShowDiffIcon"
        placeholder="Description"
        :diffChanges="(actionDiff || {}).homework || {}"
        :assetSignedUrlsHash="(actionSignedUrls.homework || {}).assets || []"
        :showImageBrief="showImageBrief"
        :fileValidators="fileValidators"
        :assetDiffIdentifierPrefix="`action.homework.assets[<assetIndex>]`"
        :descriptionDiffIdentifier="`action.homework.description`"
        :sectionIdentifier="`action.homework`"
        :requireDeleteAsset="true"
        :requireAddAsset="true"
        title="Homework"
        :stepIndex="NaN"
        :isAssetMandatory="isAssetUploadRequired"
        @deleteBlock="() => {}"
        :requireDeleteBlock="false"
        :descriptionMaxLength="5000"
        :canAppproveAsset="canAppproveAsset"
        :canFinalizeAsset="true"
        :returnEmptyStringInsteadOfDefaultJsonInDescription="true"
        :showValidationError="false"
      />
    </div> -->
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Step from '@/components/inputs/Step';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import BlockTitle from '@/components/inputs/BlockTitle';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import MultipleAssetUploader from '@/components/inputs/MultipleAssetUploader';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled } from '@/utils/slideLayoutHelpers.js';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';

export default {
  name: 'AnalyseSection',
  components: {
    Step,
    RichTextEditor,
    BlockTitleWithSubtitle,
    XSDisplayCard,
    MultipleAssetUploader,
    BlockTitle,
    XSRightSwitch,
    MultiSlideLayout,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
      COMPONENT_TO_DS_MAP,
    };
  },
  methods: {
    ...mapActions([
      'toggleAnalysisDiscussionInstructionTeacherTipField',
      'importAnalysisExplanationAssetAction',
      'deleteAnalysisExplanationAssetAction',
      'toggleActionInstructionTeacherTipField',
      'addInstructionStepForPath',
      'addInstructionSubStepForPath',
      'deleteInstructionStepForPath',
      'deleteInstructionSubStepForPath',
      'toggleInstructionTeacherTipBasedOnPath',
    ]),
    getFileNameFromUrl,
    isSlideLayoutEnabled,
    getAnalysisAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedBriefUrl: '',
        assetSignedUrl: '',
        loadingStatus: 'not_Started',
      };
      if (this.analysisSignedUrls) {
        let instruction = this.analysisSignedUrls.discussion.instructions;
        if (instruction) {
          instruction = instruction[stepIndex];
        }
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = asset.brief.assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
    isDiscussionKeyQuestionChanged(discussionName) {
      try {
        if (this.canShowDiffIcon && this.analysisDiff[discussionName]) {
          return this.analysisDiff[discussionName].key_question.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiscussionKeyQuestionGuideChanged(discussionName) {
      try {
        if (this.canShowDiffIcon && this.analysisDiff[discussionName]) {
          return this.analysisDiff[discussionName].key_question_guide.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiscussionTeacherTipGuideChanged(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].teacher_tip.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isADStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.discussion.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isStepHomeworkDiffChanged(stepIndex, entity) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.analysisDiff.discussion.instructions[stepIndex].homework[entity].isChanged == true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getExaplanationDiff(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].explanation;
        }
      } catch (error) {
        return {};
      }

      return {};
    },
    isGDStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.generalized_discussion.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isHOTSStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.higher_order_thinking.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isActionTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },

    getCodingConnectionInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedBriefUrl: '',
        assetSignedUrl: '',
        loadingStatus: 'not_started',
      };
      if (this.analysisSignedUrls) {
        let instruction = this.analysisSignedUrls.coding_connection.instructions[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = (asset.brief || {}).assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }
      return previewUrl;
    },
    getActionInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedBriefUrl: '',
        assetSignedUrl: '',
        loadingStatus: 'not_started',
      };
      if (this.actionSignedUrls) {
        let instruction = this.actionSignedUrls.instructions[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = (asset.brief || {}).assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
    isSubStepDescriptionChanged(stepIndex, subStepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.actionDiff.instructions[stepIndex].children[subStepIndex].description.isChanged ==
            true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.assets[assetIndex].brief.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].assetId.isChanged == true
            );
          } else {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].briefAssetId.isChanged ==
              true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    addAnalysisActivityDiscussionStep: {
      type: Function,
      required: true,
    },
    addAnalysiDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisActivityDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisGeneralizedDiscussionStep: {
      type: Function,
      required: true,
    },
    addAnalysisGeneralizedDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisHigherOrderStep: {
      type: Function,
      required: true,
    },
    addAnalysisHigherOrderStep: {
      type: Function,
      required: true,
    },
    addAnaysisExplanationAssets: {
      type: Function,
      required: true,
    },
    deleteAnaysisExplanationAssets: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    analysisSignedUrls: {
      type: Object,
      required: true,
    },
    analysisDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    schema: {
      type: Object,
      required: true,
    },
    addAnalysisSubStep: {
      type: Function,
      required: true,
    },
    canAppproveAsset: { type: Boolean, required: true },
    deleteAnalysisDiscussionSubStep: {
      type: Function,
      required: true,
    },

    addActionStep: {
      type: Function,
      required: true,
    },
    deleteActionStep: {
      type: Function,
      required: true,
    },
    deleteActionSubStep: {
      type: Function,
      required: true,
    },
    addActionSubStep: {
      type: Function,
      required: true,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    signedUrls: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      analysisBockStaticTexts: (state) => state.lessonPlan.staticTexts.analysis,
    }),
  },
};
</script>
