import { LAYOUT_1_OBJECT } from '@/components/lessonPlanTemplate/commonLpComponents/layouts/generic/layoutObject.js';
import { cloneDeep } from 'lodash';
const INSTRUCTION_COMPONENT = [
  {
    description: '',
    children: [],
    instructionBreak: false,
    assets: [],
  },
];
const EXPLANATION_COMPONENT = [
  {
    description: '',
    assetId: '',
    brief: {
      text: '',
      assetId: '',
    },
    assetApproved: false,
  },
];

const SLIDE_COMPONENT = [LAYOUT_1_OBJECT];

const SLIDE_INSTRUCTION_COMPONENT = {
  instructions: cloneDeep(SLIDE_COMPONENT),
};

const ANALYSIS_PROGRAM_LEGACY_DATA = {
  key_question: '',
  key_question_guide: '',
  explanations: [],
  instructions: [
    {
      description: '',
    },
  ],
};

const ACTION_SECTION_STANDARD_LP = {
  instructions: cloneDeep(INSTRUCTION_COMPONENT),
  teacher_tip: '',
};

const ANALYSIS_DISCUSSION_STANDARD_LP = {
  explanations: [],
  key_question: '',
  key_question_guide: '',
  instructions: [],
  teacher_tip: '',
  key_question_assets: [],
};

const MORE_PRACTICE = [
  {
    description: '',
    assets: [],
  },
];

const COMPONENT_TO_DS_MAP = {
  INSTRUCTION: INSTRUCTION_COMPONENT,
  SLIDE_LAYOUT: SLIDE_COMPONENT,
  SLIDE_INSTRUCTION: SLIDE_INSTRUCTION_COMPONENT,
  ANALYSIS_PROGRAM_LEGACY_DATA: ANALYSIS_PROGRAM_LEGACY_DATA,
  MORE_PRACTICE: MORE_PRACTICE,
  STANDARD_LP_ACTION: ACTION_SECTION_STANDARD_LP,
  ANALYSIS_DISCUSSION_STANDARD_LP: ANALYSIS_DISCUSSION_STANDARD_LP,
  ANALYSIS_EXPLANATION_STANDARD_LP: EXPLANATION_COMPONENT,
};

export { COMPONENT_TO_DS_MAP };
