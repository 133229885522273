<template>
  <div>
    <v-select
      label="Select Layout"
      item-text="label"
      item-value="name"
      v-model="selectedLayout"
      :items="layoutOptions"
    ></v-select>
    <div :key="layoutIdentifier">
      <component
        :is="currentLayoutComponent"
        v-if="currentLayoutComponent"
        :subPath="subPath"
        :assetSubPath="assetSubPath"
        :data="lessonPlanData"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :canAppproveAsset="canAppproveAsset"
        :signedUrls="signedUrls"
        :layoutName="layoutData.layoutName"
        :layoutTemplate="layoutData.layoutTemplate"
        :components="layoutData.components"
        :isAssetUploadRequired="isAssetUploadRequired"
        :canShowDiffIcon="canShowDiffIcon"
        :showImageBrief="showImageBrief"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { snakeCase, cloneDeep, get } from 'lodash';
import layouts from './layouts/layouts';
import { getLayoutStructure } from './layouts/generic/layoutStructure';
import {
  LAYOUT_1_OBJECT,
  LAYOUT_2_OBJECT,
  LAYOUT_3_OBJECT,
  LAYOUT_4_OBJECT,
  LAYOUT_5_OBJECT,
  LAYOUT_6_OBJECT,
  LAYOUT_7_OBJECT,
} from './layouts/generic/layoutObject';

export default {
  name: 'SlideLayout',
  data() {
    return {
      slideChanged: 0,
      LAYOUT_1_OBJECT,
      LAYOUT_2_OBJECT,
      LAYOUT_3_OBJECT,
      LAYOUT_4_OBJECT,
      LAYOUT_5_OBJECT,
      LAYOUT_6_OBJECT,
      LAYOUT_7_OBJECT,
      selectedLayout: '',
      currentLayoutComponent: null,
      layoutOptions: [
        { name: 'layout1', label: 'Layout 1' },
        { name: 'layout2', label: 'Layout 2' },
        { name: 'layout3', label: 'Layout 3' },
        { name: 'layout4', label: 'Layout 4' },
        { name: 'layout5', label: 'Layout 5' },
        { name: 'layout6', label: 'Layout 6' },
        { name: 'layout7', label: 'Layout 7' },
      ],
      layoutData: {},
    };
  },
  computed: {
    computeLayoutIdentifier() {
      return 'dynamic-slide-layout' + Math.random().toString(36).slice(2);
    },
    layoutIdentifier() {
      return this.computeLayoutIdentifier + this.subPath + this.slideChanged;
    },
    canUpdateLayoutDataObject() {
      const currentSlide = get(this.lessonPlanData, this.subPath, {});
      return this.selectedLayout != currentSlide.layoutName;
    },
  },
  watch: {
    selectedLayout: {
      immediate: true,
      handler(newLayout, oldValue) {
        if (oldValue != newLayout && newLayout != '') {
          this.layoutData = cloneDeep(this.getLayoutStructure(newLayout, this.$props));
          if (this.canUpdateLayoutDataObject) {
            this.updateDeepPathValue({
              subPath: this.subPath,
              value: cloneDeep(this[`${snakeCase(newLayout).toUpperCase()}_OBJECT`]),
            });
          }
          this.slideChanged++;
          this.loadLayout(newLayout);
        }
      },
    },
  },
  methods: {
    ...mapActions(['updateDeepPathValue']),
    getLayoutStructure,
    async loadLayout(layoutName) {
      if (layouts[layoutName]) {
        const component = await layouts[layoutName]();
        this.currentLayoutComponent = component.default || component;
      } else {
        this.currentLayoutComponent = null;
      }
    },
  },
  mounted() {
    const currentSlide = get(this.lessonPlanData, this.subPath);

    if (currentSlide.layoutName) {
      this.selectedLayout = currentSlide.layoutName;
    } else {
      this.selectedLayout = 'layout1';
    }
  },
  props: {
    subPath: {
      type: String,
      default: '',
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    fileValidators: {
      type: Array,
      default: () => [],
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => {},
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    assetSubPath: {
      type: String,
      default: '',
    },
  },
};
</script>
