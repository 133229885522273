<template>
  <div class="rich-text-editor-wrapper">
    <v-dialog
      content-class="equation-editor-dialog-content"
      v-model="showEquationEditorDialog"
      width="80%"
      height="80%"
      @click:outside="closeEquationEditorDialog"
    >
      <div v-if="showEquationEditorDialog">
        <EquationEditorDialogContent
          :defaultValueJson="equationData"
          @insertLatexInForm="insertLatexInForm"
          @closeEquationEditorDialog="closeEquationEditorDialog"
        />
      </div>
    </v-dialog>
    <!-- This is single level cyclic reference. And the if statement keeps it single level
    also prevents it from initial render
    CYCLIC REFERENCE WAY IS CHOSEN AS ALL FEATURES OF RTE IS NEEDED IN FULL SCREEN MODE TOO
    AND THIS AVOIDS CODE DUPLICATION
     -->
    <FullScreenRichTextEditor
      :fieldIdentifier="fieldIdentifier"
      :counter="counter"
      :wordCounter="wordCounter"
      :allowWordCount="allowWordCount"
      :allowCharacterCount="isCharacterCounterAllowed"
      :includeOnlyToolBarActions="includeOnlyToolBarActions"
      :excludeOnlyToolBarActions="excludeOnlyToolBarActions"
      v-model="triggerFullScreen"
      v-if="!inFullScreenMode && triggerFullScreen"
      :originalData="originalData"
      :fullScreenRevision="fullScreenRevision"
      @dataFromFullScreenView="updateDataFromFullScreenView"
      :isAssetUploadRequired="isAssetUploadRequired"
      :key="`fullScreenRte${fullScreenRevision}${rteIdentifier}`"
      :readonly="readonly"
      :showValidationError="showValidationError"
    />
    <!-- END OF CYCLIC REFERENCE -->
    <div
      :class="`${!readonly ? 'editor' : 'editor-disabled'} editor__content`"
      :style="
        (showValidationError && errorHelper) || remainingCharacter < 0 || remainingWordCount < 0
          ? 'border: 1px solid red; border-radius: 7px'
          : ''
      "
    >
      <div v-if="placeholder" class="rte-placeholder-wrapper">
        <div>{{ placeholder }}</div>
        <v-divider />
      </div>
      <editor-menu-bar
        :class="`editor-menu-bar ${removeAllPluginExceptFullScreen ? 'show-only-fullScreen' : ''}`"
        :editor="editor"
        v-slot="{ commands, getMarkAttrs, isActive }"
        v-if="(inFullScreenMode ? !readonly : true)"
      >
        <div class="menubar" style="display: flex; flex-flow: wrap;">
          <div style="display: flex;">
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('fontFamily') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <FontFamilyPicker
                :value="getMarkAttrs('fontFamily').fontFamily"
                :onSelect="(val) => commands.fontFamily({ fontFamily: val })"
                :subjectName="subjectName"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('fontSize') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('fontSize') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <div style="padding-left: 3px; font-size: 0.8em; padding-top: 7px;">Size</div>
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('fontSize') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                style="padding: 0px;"
                :isActive="getMarkAttrs('fontSize').fontSize === '12px'"
                :onClick="() => commands.fontSize({ fontSize: '12px' })"
                :iconSize="12"
                iconName="smallFont.svg"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('fontSize') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                style="padding: 0px;"
                :isActive="getMarkAttrs('fontSize').fontSize === '20px'"
                :onClick="() => commands.fontSize({ fontSize: '20px' })"
                iconName="bigFont.svg"
                :iconSize="16"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('unorderedList') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('unorderedList') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.bullet_list"
                :isActive="isActive.bullet_list()"
                iconName="unorderedList.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('orderedList') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('orderedList') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.ordered_list"
                :isActive="isActive.ordered_list()"
                iconName="orderedList.svg"
              />
            </MenubarItemWrapper>

            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('fontColor') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
          </div>
          <!-- <XSDivider /> -->
          <div style="display: flex;">
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('fontColor') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ColorPicker
                :onChange="
                  (val) => {
                    commands.fontTextColor({ fontTextColor: val });
                  }
                "
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="canRenderActionButton('bold') && !removeAllPluginExceptFullScreen && !readonly"
            />
            <MenubarItemWrapper
              v-if="canRenderActionButton('bold') && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="commands.bold"
                :isActive="isActive.bold()"
                iconName="bold.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('italic') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('italic') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.italic"
                :isActive="isActive.italic()"
                iconName="italic.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('underline') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('underline') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.underline"
                :isActive="isActive.underline()"
                iconName="underline.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('strike') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('strike') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.strike"
                :isActive="isActive.strike()"
                iconName="strikethrough.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('superscript') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('superscript') &&
                !removeAllPluginExceptFullScreen &&
                !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.superscript"
                :isActive="isActive.superscript()"
                iconName="superscript.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('subscript') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('subscript') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                :onClick="commands.subscript"
                :isActive="isActive.subscript()"
                iconName="subscript.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="
                canRenderActionButton('equation') && !removeAllPluginExceptFullScreen && !readonly
              "
            />
            <MenubarItemWrapper
              v-if="
                canRenderActionButton('equation') && !removeAllPluginExceptFullScreen && !readonly
              "
            >
              <ToolBarActionButton
                :onClick="() => openEquationEditorDialog(commands.equation)"
                :isActive="false"
                iconName="equation.svg"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="canRenderActionButton('image') && !removeAllPluginExceptFullScreen && !readonly"
            />
            <MenubarItemWrapper
              v-if="canRenderActionButton('image') && !removeAllPluginExceptFullScreen && !readonly"
            >
              <v-dialog v-model="showCentralizedAssetUploaderDialog" width="400">
                <template v-slot:activator="{ on }">
                  <ToolBarActionButton
                    :onClick="() => openCentralizedAssetUploadDialog(commands.image)"
                    :isActive="false"
                    iconName="image-icon.svg"
                    v-on="on"
                  />
                </template>
                <v-card v-if="showCentralizedAssetUploaderDialog">
                  <div
                    style="
                      background-color: #f6f6f6;
                      padding: 10px;
                      height: 56px;
                      text-align: right;
                    "
                  >
                    <v-btn
                      color="primary"
                      icon
                      @click="() => (showCentralizedAssetUploaderDialog = false)"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </div>
                  <v-card-title>
                    <h2>Add Asset</h2>
                  </v-card-title>
                  <v-card-text :style="!isAssetUploadRequired ? 'height: 300px;' : ''">
                    <AssetVaultUpload
                      :key="fieldIdentifier"
                      :preset="CA_QUESTION_PRESET"
                      :appName="CA_APP_NAME"
                      :assetResponseHook="insertImage"
                      :placeholder="placeholderTextForAssetUploadVault"
                      :acceptedFileTypes="acceptedFileTypes"
                      :enableBlankImageInsert="true"
                    >
                      <!-- This will be a recursive component which will be passed
                    as a slot to assetVaultUpload with custom props-->
                      <div
                        style="height: 100px; max-height: 100px; margin-bottom: 60px; color: black;"
                        v-if="!isAssetUploadRequired"
                      >
                        <RichTextEditor
                          :readonly="readonly"
                          :canShowDiffIcon="false"
                          v-model="briefText"
                          :fieldIdentifier="`${fieldIdentifier}`"
                          :counter="3000"
                          :allowCharacterCount="true"
                          :includeOnlyToolBarActions="getBriefToolBar()"
                          :isAssetUploadRequired="isAssetUploadRequired"
                          :removeAllPluginExceptFullScreen="true"
                          :showValidationError="false"
                          placeholder="Image Brief"
                          :isImageBrief="true"
                        />
                      </div>
                    </AssetVaultUpload>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="canRenderActionButton('blank') && !removeAllPluginExceptFullScreen && !readonly"
            />
            <MenubarItemWrapper v-if="canRenderActionButton('blank')">
              <ToolBarActionButton
                :onClick="() => insertBlank(commands.blank)"
                :isActive="false"
                icon="mdi-numeric-1-circle-outline"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="canRenderActionButton('table') && !removeAllPluginExceptFullScreen && !readonly"
            />
          </div>
          <div style="display: flex;">
            <MenubarItemWrapper
              v-if="canRenderActionButton('table') && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="
                  () => commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })
                "
                :isActive="false"
                icon="mdi-table"
              />
            </MenubarItemWrapper>
            <v-divider
              class="verical-divider mx-1"
              vertical
              v-if="canRenderActionButton('table') && !removeAllPluginExceptFullScreen && !readonly"
            />

            <!-- These Actions are available only if table is active in RTE -->
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.addColumnBefore()"
                :isActive="false"
                icon="mdi-table-column-plus-before"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.addColumnAfter()"
                :isActive="false"
                icon="mdi-table-column-plus-after"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.deleteColumn()"
                :isActive="false"
                icon=" mdi-table-column-remove"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.addRowBefore()"
                :isActive="false"
                icon="mdi-table-row-plus-before"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.addRowAfter()"
                :isActive="false"
                icon="mdi-table-row-plus-after"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.deleteRow()"
                :isActive="false"
                icon="mdi-table-row-remove"
              />
            </MenubarItemWrapper>
          </div>
          <div style="display: flex;">
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ToolBarActionButton
                :onClick="() => commands.deleteTable()"
                :isActive="false"
                iconName="delete-table.svg"
              />
            </MenubarItemWrapper>
            <MenubarItemWrapper
              v-show="isActive.table() && !removeAllPluginExceptFullScreen && !readonly"
            >
              <ColorPicker
                :icon="tableColorIcon"
                :onChange="
                  (val) => {
                    commands.changeCellColor(val);
                  }
                "
              />
            </MenubarItemWrapper>
            <!-- END of table specific icons -->
            <MenubarItemWrapper v-if="canRenderActionButton('fullScreen') && !inFullScreenMode">
              <ToolBarActionButton
                :onClick="() => openEditorInFullScreenMode()"
                :isActive="false"
                icon="mdi-fullscreen"
              />
            </MenubarItemWrapper>
          </div>
        </div>
      </editor-menu-bar>
      <v-row style="cursor: text;">
        <v-col
          :style="`position: relative; display: flex; ${
            value.length == 0 ? 'color: rgba(0, 0, 0, 0.6)' : ''
          };`"
        >
          <!-- This needs to be wrapped by editor-menu-bar in order to access commands in the emit events -->
          <editor-menu-bar :editor="editor" v-slot="{ commands }">
            <editor-content
              :class="`${
                inFullScreenMode
                  ? 'editor-content-full-screen-view'
                  : isImageBrief
                  ? 'editor-content-brief'
                  : 'editor-content'
              }`"
              :editor="editor"
              @trigger-equation-data-update="setEquationData($event, commands.equation)"
            />
          </editor-menu-bar>
          <FooterText
            :remainingCharacterCount="
              isCharacterCounterAllowed ? remainingCharacter + ' Characters remaining' : ''
            "
            :remainingWordCount="allowWordCount ? remainingWordCount + ' Words remaining' : ''"
          />
        </v-col>
        <div
          :style="`width: ${
            canShowDiffIcon ? '64px' : '10px'
          }; margin-bottom: -10px; display: flex;`"
        >
          <DiffButton
            v-if="canShowDiffIcon"
            class="diff-button"
            :fieldIdentifier="fieldIdentifier"
            :isDiffChanged="isDiffChanged"
            :canShowDiffIcon="canShowDiffIcon"
            :projectType="projectType"
            :subType="subType"
          />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import tableColorIcon from '@/assets/RTE/tableColor.svg';
import { mapMutations } from 'vuex';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import ColorPicker from '@/components/RTE/components/ColorPicker';
import MenubarItemWrapper from '@/components/RTE/components/MenubarItemWrapper';
import FontFamilyPicker from '@/components/RTE/components/FontFamilyPicker';
import ToolBarActionButton from '@/components/RTE/components/ToolBarActionButton';
import EquationEditorDialogContent from '@/components/inputs/EquationEditorDialogContent';
import DiffButton from '@/components/ContentDiff/DiffButton';
import FooterText from '@/components/RTE/components/FooterText';
import {
  MaxWordCount,
  MaxCharacterCount,
  Image,
  SanitizeRteJson,
} from '@xseededucation/rte-helper-functions';
import AssetVaultUpload from '@/components/AssetVault/AssetVaultUpload';
import { CA_APP_NAME, CA_QUESTION_PRESET } from '@/constants/centralizedAssetContants.js';
import {
  EXTENSIONS,
  isRteEmpty,
  computeCellWidthInPercentageForTable,
} from '@xseededucation/rte-helper-functions';
import { MIME_TO_EXT_MAP } from './utils/constants';
import { mdToHtml } from '@/utils';
import { Placeholder } from 'tiptap-extensions';
import _ from 'lodash';
import { getBlanksCount } from '@xseededucation/rte-helper-functions';
import FullScreenRichTextEditor from '@/components/RTE/components/FullScreenRichTextEditor';
import { mapState } from 'vuex';
var valueChangeTimer,
  valueChangeLock = false;
export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    ColorPicker,
    FontFamilyPicker,
    ToolBarActionButton,
    EquationEditorDialogContent,
    DiffButton,
    MenubarItemWrapper,
    FooterText,
    AssetVaultUpload,
    FullScreenRichTextEditor,
  },
  data() {
    return {
      // eslint-disable-next-line
      tableColorIcon,
      output: '',
      CA_APP_NAME,
      CA_QUESTION_PRESET,
      isEditorOnFocus: false,
      showEquationEditorDialog: false,
      showCentralizedAssetUploaderDialog: false,
      editor: new Editor({
        onInit: this.onInit,
        onFocus: this.onEditorFocus,
        onBlur: this.onEditorBlur,
        onUpdate: this.onValueUpdate,
        content: this.value ? this.getParsedContent(this.value) : null,
        editable: !this.readonly,
        extensions: [
          ...EXTENSIONS,
          new MaxCharacterCount({ maxSize: this.counter, remainingCharacter: this.counter }),
          new MaxWordCount({ maxWordSize: this.wordCounter, remainingWords: this.wordCounter }),
          new SanitizeRteJson(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Image({ isAssetUploadRequired: this.isAssetUploadRequired }),
        ],
      }),
      remainingCharacter: 0,
      remainingWordCount: 0,
      rteIdentifier: '',
      firstCall: true,
      triggerFullScreen: false,
      originalData: '',
      fullScreenRevision: 0,
      editorMenuBarKey: 0,
      briefText: '',
      markClassNameToToolBarNameMap: {
        fontFamily: 'fontFamily',
        fontSize: 'fontSize',
        bold: 'bold',
        italic: 'italic',
        underline: 'underline',
        strike: 'strike',
        superscript: 'subscript',
        subscript: 'subscript',
      },
      markClassNamesAvailable: [],
    };
  },
  created() {
    if (
      this.subjectName &&
      this.subjectName.toLowerCase() == 'hindi' &&
      !this.includeOnlyToolBarActions.includes('fontFamily')
    ) {
      this.includeOnlyToolBarActions.push('fontFamily');
    }
    this.includeOnlyToolBarActions.forEach((_markName) => {
      if (this.markClassNameToToolBarNameMap[_markName]) {
        this.markClassNamesAvailable.push(this.markClassNameToToolBarNameMap[_markName]);
      }
    });
  },
  computed: {
    ...mapState({
      subjectName: (state) => state.lessonPlan.subjectName,
    }),
    isCharacterCounterAllowed() {
      if (this.allowCharacterCount == undefined) {
        return this.counter && this.counter > 1;
      } else {
        return this.allowCharacterCount;
      }
    },
    placeholderTextForAssetUploadVault() {
      let placeholderText = 'Supported (';
      this.acceptedFileTypes.forEach((_mime) => {
        placeholderText += MIME_TO_EXT_MAP[_mime] + '/';
      });
      placeholderText = placeholderText.slice(0, -1);
      return (placeholderText += ')');
    },
    errorHelper() {
      if (this.value && this.value.length > 0) {
        return isRteEmpty(this.value);
      }
      return true;
    },
    showToolbarActions() {
      return _.difference(this.includeOnlyToolBarActions, this.excludeOnlyToolBarActions);
    },
  },
  beforeMount() {
    this.firstCall = true;
  },
  watch: {
    subjectName: {
      immediate: true,
      handler(val) {
        if (val) {
          let indexOfSanitizeRte = this.editor.options.extensions.findIndex(
            (i) => i.name == 'sanitizeRteJson'
          );
          this.editor.options.extensions[
            indexOfSanitizeRte
          ].options.subjectName = val.toLowerCase();
          this.editor.options.extensions[
            indexOfSanitizeRte
          ].options.markClassNamesVisibleToUser = this.markClassNamesAvailable;
        }
      },
    },
    showValidationError: {
      immediate: true,
      handler() {
        this.generateIdentifier();
        this.rteValidityStatus();
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        let { onValueChangeWatcher } = this;
        setTimeout(function () {
          onValueChangeWatcher();
        }, 0); // Making the opration asyncronus and stop blocking the main thread
      },
    },
  },

  beforeDestroy() {
    this.RTE_VALIDATOR({ id: this.rteIdentifier, action: 'remove' });
    if (this.inFullScreenMode) {
      this.editor.destroy();
    }
  },
  methods: {
    ...mapMutations(['RTE_VALIDATOR']),
    mdToHtml,
    getBriefToolBar() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'strike',
        'superscript',
        'subscript',
        'equation',
        'fullScreen',
        'table',
      ];
    },
    getBlanksCount,
    updateDataFromFullScreenView(val) {
      //Sets content of RTE and triggeres update event
      this.editor.setContent(this.getParsedContent(val), true);
    },
    openEditorInFullScreenMode() {
      this.fullScreenRevision += 1;
      this.originalData = JSON.stringify(this.editor.getJSON());
      this.triggerFullScreen = true;
    },
    rteValidityStatus() {
      //RTE validation should happen only in original mode and not in full screen mode
      //As this might cause some unexpected behaviour

      if (!this.inFullScreenMode) {
        if (
          this.remainingCharacter < 0 ||
          this.remainingWordCount < 0 ||
          (this.showValidationError && isRteEmpty(this.value))
        ) {
          this.RTE_VALIDATOR({ id: this.rteIdentifier, status: false, action: 'add' });
        } else {
          this.RTE_VALIDATOR({ id: this.rteIdentifier, action: 'remove' });
        }
      }
    },
    generateIdentifier() {
      this.rteIdentifier = this.rteIdentifier || Math.random().toString(36).slice(2);
    },
    canRenderActionButton(action) {
      if (this.showToolbarActions.indexOf(action) > -1) {
        return true;
      }
      return false;
    },
    setEquationData(eventData, command) {
      this.equationData = eventData;
      this.showEquationEditorDialog = true;
      this.currentCommand = command;
    },
    onEditorFocus() {
      this.editor.options.editable = !this.readonly;
      if (this.value.length == 0) this.editor.clearContent();
      this.isEditorOnFocus = true;
    },
    onEditorBlur() {
      this.$emit('blur', this.value);
      this.isEditorOnFocus = false;
    },
    handleOnValueUpdate(data) {
      let _ = this;
      this.editorMenuBarKey += 1;
      let jsonData = data.getJSON();
      if (this.canRenderActionButton('table')) {
        computeCellWidthInPercentageForTable(jsonData.content);
      }
      var value = JSON.stringify(jsonData);

      if (
        this.returnEmptyStringInsteadOfDefaultJson &&
        value == '{"type":"doc","content":[{"type":"paragraph"}]}'
      ) {
        value = '';
      }

      setTimeout(function () {
        _.$emit('input', value);
      }, 0);
    },
    onValueUpdate(data) {
      let _ = this;
      if (valueChangeLock == true) {
        clearTimeout(valueChangeTimer);
      }

      valueChangeLock = true;
      valueChangeTimer = setTimeout(function () {
        _.handleOnValueUpdate(data);
        valueChangeLock = false;
      }, 500);
    },
    getParsedContent(val) {
      try {
        return JSON.parse(val);
      } catch (err) {
        return this.preProcessMarkDownText(val);
      }
    },
    preProcessMarkDownText(val) {
      try {
        const equationEditorJsonArray = this.$store.state.lessonPlan.equationEditorJsonArray || [];
        if (this.$store.state.lessonPlan.subjectName === 'Hindi') {
          return {
            type: 'doc',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    marks: [{ type: 'fontFamily', attrs: { fontFamily: 'ChanakyaXUniN' } }],
                    text: val,
                  },
                ],
              },
            ],
          };
        }
        return this.mdToHtml(val, equationEditorJsonArray);
      } catch (error) {
        console.log(error);
        return val;
      }
    },
    insertLatexInForm(latex, equationJSONString) {
      this.currentCommand({ latex, equationJSONString });

      this.showEquationEditorDialog = false;
      // this.editor.setContent(this.editor.getHTML() + latex, true);
    },
    insertImage(info) {
      let sourceUrl;
      if (!info.isEmpty) {
        sourceUrl = info.data.optimized ? info.data.optimized : info.data.original;
      }
      if (this.isAssetUploadRequired) {
        this.currentCommand({
          assetId: info._id,
          src: sourceUrl,
          width: '150',
          assetApproved: this.forceAssetFinalized,
        });
      } else {
        if (info.isEmpty) {
          this.currentCommand({
            briefAssetId: null,
            briefSrc: null,
            width: '100',
            brief: this.briefText,
          });
        } else {
          this.currentCommand({
            briefAssetId: info._id,
            briefSrc: sourceUrl,
            width: '150',
            brief: this.briefText,
          });
        }
      }
      this.showCentralizedAssetUploaderDialog = false;
    },
    openCentralizedAssetUploadDialog(command) {
      this.showCentralizedAssetUploaderDialog = true;
      this.briefText = '';
      this.currentCommand = command;
    },
    openEquationEditorDialog(command) {
      this.equationData = {};
      this.showEquationEditorDialog = true;
      this.currentCommand = command;
    },
    closeEquationEditorDialog() {
      this.showEquationEditorDialog = false;
      this.equationData = {};
    },
    insertBlank(command) {
      // Avoid inserting blank continuously to avoid mis-calulating the blank sequence
      if (valueChangeLock) return;

      this.currentCommand = command;
      let sequenceNumber = 0;
      if (this.value != '') {
        let content;
        if (typeof this.value == 'string') {
          content = JSON.parse(this.value).content;
        } else {
          content = this.value.content;
        }
        sequenceNumber = this.getBlanksCount(content, 0);
      }
      this.currentCommand({ sequence: sequenceNumber });
    },
    onValueChangeWatcher() {
      if (this.firstCall) {
        if (this.rteIdentifier == '') {
          this.generateIdentifier();
        }
      }
      if (this.isCharacterCounterAllowed) {
        if (!isRteEmpty(this.value)) {
          if (this.firstCall) {
            this.editor.options.extensions[
              this.editor.options.extensions.findIndex((i) => i.name == 'maxCharacterCount')
            ].options.countCharacters(this.editor.view.state.doc);
          }
          this.remainingCharacter = this.editor.options.extensions[
            this.editor.options.extensions.findIndex((i) => i.name == 'maxCharacterCount')
          ].options.remainingCharacter;
        } else {
          this.remainingCharacter = this.counter;
          this.editor.options.extensions[
            this.editor.options.extensions.findIndex((i) => i.name == 'maxCharacterCount')
          ].options.maxSize = this.counter;
        }
      }
      if (this.allowWordCount) {
        if (!isRteEmpty(this.value)) {
          if (this.firstCall) {
            this.editor.options.extensions[
              this.editor.options.extensions.findIndex((i) => i.name == 'maxWordCount')
            ].options.countWords(this.editor.view.state.doc);
          }
          this.remainingWordCount = this.editor.options.extensions[
            this.editor.options.extensions.findIndex((i) => i.name == 'maxWordCount')
          ].options.remainingWords;
        } else {
          this.remainingWordCount = this.wordCounter;
          this.editor.options.extensions[
            this.editor.options.extensions.findIndex((i) => i.name == 'maxWordCount')
          ].options.remainingWords = this.wordCounter;
        }
      }
      this.rteValidityStatus();
      this.firstCall = false;
    },
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDiffButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClickDiff: {
      type: Function,
      default: () => {},
    },
    canShowDiffIcon: {
      type: Boolean,
      required: false,
    },
    isDiffChanged: {
      type: Boolean,
      required: false,
    },
    fieldIdentifier: {
      type: String,
      required: false,
    },
    showValidationError: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    projectType: {
      type: String,
      default: 'noQuestion',
    },
    subType: {
      type: String,
      default: '',
    },
    counter: {
      type: Number,
      default: 0,
    },
    wordCounter: {
      type: Number,
      default: 0,
    },
    allowWordCount: {
      type: Boolean,
      default: false,
    },
    allowCharacterCount: {
      type: Boolean,
      default: undefined,
    },
    excludeOnlyToolBarActions: {
      type: Array,
      default: () => [],
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      default: false,
    },
    removeAllPluginExceptFullScreen: {
      type: Boolean,
      default: false,
    },
    includeOnlyToolBarActions: {
      type: Array,
      default: () => [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'fullScreen',
      ],
    },
    acceptedFileTypes: {
      type: Array,
      default: () => ['image/png', 'image/jpeg'],
    },
    inFullScreenMode: {
      type: Boolean,
      default: false,
    },
    isImageBrief: {
      type: Boolean,
      default: false,
    },
    returnEmptyStringInsteadOfDefaultJson: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
export const BASIC_TOOLS = [
  'bold',
  'italic',
  'underline',
  'strike',
  'superscript',
  'subscript',
  'equation',
  'fullScreen',
];
</script>
<style scoped>
.tiptap-toolbar-action {
  background-color: #f5f5f5 !important;
  border-radius: 0px;
  box-shadow: none;
  height: 35px !important;
}
.tiptap-toolbar-action.is-active {
  background-color: #e1e1e1 !important;
}
.rich-text-editor-wrapper {
  background: white;
  border-radius: 7px;
}
.editor {
  border: 1px solid #979797;
  border-radius: 5px;
}
.editor-disabled {
  border: 1px solid #979797;
  border-radius: 5px;
  color: grey;
}
.editor-content {
  max-height: 220px;
  overflow: auto;
  width: 100%;
}
.editor-content-brief {
  max-height: 70px;
  overflow: auto;
  width: 100%;
}
.editor-content-full-screen-view {
  height: 320px !important;
  max-height: 320px !important;
  overflow: auto;
  width: 100%;
}
.editor-menu-bar {
  background-color: #f5f5f5;
  padding: 10px 10px;
  border-bottom: 1px solid #979797;
  border-radius: 5px 5px 0px 0px;
}
.show-only-fullScreen {
  flex-direction: row-reverse !important;
}
.action-button-divider {
  border-right: 1px solid #d5d5d5;
}
.verical-divider {
  height: 30px;
  padding-bottom: 10px;
}
.diff-button {
  width: 50px;
  background-color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #979797;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 0px;
  /* position: absolute; */
  right: 0;
  bottom: 0;
  height: 100%;
  top: 0;
}
.diff-button button {
  height: 100% !important;
}
.rte-placeholder-wrapper {
  text-align: start;
  padding-left: 10px;
  background: #999;
  color: white;
}
</style>
